<template>
    <section class="app-main" :class="{'route-class': $route.fullPath == '/'}">
        <!-- <keep-alive :include="cachedViews">
            <router-view :key="key" />
        </keep-alive> -->
        <router-view />
    </section>
</template>
<script>
export default {
    name: 'AppMain',
    computed: {
        // cachedViews() {
        //     return this.$store.state.tagsView.cachedViews
        // },
        // key() {
        //     return this.$route.path
        // }
    }
}
</script>

<style lang="less" scoped>
.app-main {
    /* 50= head  50  */
    width: calc(100% - 134px);
    // max-height: 100vh;
    // overflow-x: hidden;
    padding: 70px 20px 20px 20px;
    box-sizing: border-box;
    // margin-left: 134px;
    margin-left: 240px;

    & /deep/.boxright {
        width: 100%;
        padding: 0;
        position: static;
        margin-left: 0;
    }
}
.route-class{
    width:100%;
    // margin-left: 0;
    margin-left: 106px;
}
</style>
