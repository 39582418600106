<template>
	<el-button @click="click()" :size="code_data.size || ''" :type="code_data.type" :loading="button_loading">{{
		code_data.label }}</el-button>
</template>
<script>
export default {
	name: 'component.button',

	props: {
		code_data: {
			type: Object,
			required: true
		},
		scope: {
			type: Object
		},
		$formData: {},
		code_this: {}
	},
	data() {
		return {
			component_id: this.code.domId(this.code_data),
			button_loading: false
		};
	},
	created() {

	},
	methods: {
		// 点击
		click() {
			if (this.code_data.action == 'WindowPage') {
				//console.log('WindowPage', this.$formData);
				if (this.code_data.parameters.click_target == 'diy') {
					if (this.code_data.parameters.click_ref == 'import') {
						this.code_this.$refs.diyImportMore[0].importMore = true;
					}
					if (this.code_data.parameters.click_ref == 'export') {
						this.code_this.$refs.diyExportMore[0].exportMore = true;
					}
					if (this.code_data.parameters.click_ref == 'log') {
						this.code_this.$refs.table_log[0].goods_id = this.scope.row.id;
					}
					if (this.code_data.parameters.click_ref == 'shopselector') {
						this.code_this.$refs.shopList[0].shoptype = true;
					}
					if (this.code_data.parameters.click_ref == 'editIntegral') {
						let arr = []; arr.push(this.scope.row.id);
						this.code_this.$refs.editIntegral[0].user_id = arr;
						this.code_this.$refs.editIntegral[0].integralVisble = true;
					}
					if (this.code_data.parameters.click_ref == 'invite') {
						this.code_this.$refs.inviteUser[0].cityDialog = true;
					}
					if (this.code_data.parameters.click_ref == 'table_share') {
						this.code_this.$refs.table_share[0].scope = this.scope.row;
						this.code_this.$refs.table_share[0].dialogShow = true;
					}
				} else {
					if (this?.scope && Object.keys(this.scope).length) {
						this.code_this.active_scope = this.scope;
					}
					//console.log(this.code_data.parameters.click_target);
					this.$formData[this.code_data.parameters.click_target] = true
				}
			} else {
				if (this.code_data?.is_confirm) {
					this.$confirm(`您确定${this.code_data.label}该${this.code_data.sub_label}吗?`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}).then(res => {
						this.button_loading = true
						this.code.buttonAction(this, this.code_data, (domThis, res) => {
							domThis.code_this.getTableData()
						});
						this.button_loading = false;
					}).catch(error => {
						//console.log(error, 'error');
					})
				} else {
					this.code.buttonAction(this, this.code_data, (domThis, res) => {
						domThis.code_this.getTableData()
					});
				}
			}

		},
		// 导入回调
		importCallback() {
			//console.log('importCallback');
		}
	},
	mounted() {
		//console.log(this.component_id);
	},
};
</script>
<style scoped>
.caozuo {
	padding: 0 10px;
	/* border-right: 1px solid var(--fontColor, #fb6638); */
	cursor: pointer;
	display: inline-block;
	line-height: 1;
	color: var(--fontColor);
	margin-left: 0 !important;
}
</style>