<template>
    <div>
        <el-select :popper-append-to-body="false" v-model="$formData[code_data.key]" filterable clearable
            :disabled="code_data.parameters?.is_disabled" :multiple="code_data.parameters?.is_multiple"
            :placeholder="code_data.parameters.placeholder ?? ''" @change="handle_click">
            <el-option v-for="(item, index) in selectOptions"
                :key="code_data.parameters?.key ? item[code_data.parameters.key] : index"
                :label="item[code_data.parameters?.label]" :value="item[code_data.parameters.value]"></el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    name: 'component.form.select',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {
            selectOptions: []
        }
    },
    watch: {
        code_data: {
            handler(newVal, oldVal) {
                this.init()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        init() {
            // 获取级联数据
            if (this.code_data.data_source && this.code_data.data_source.type == 'Api') {
                this.code_data.data_source['successCallback'] = function(domThis, res) {
                    if (!Array.isArray(res.data)) {
                        domThis.selectOptions = res.data.list;
                    } else {
                        domThis.selectOptions = res.data;
                    }
                }
                this.code_data.data_source['failCallback'] = function(domThis, err) {
                    console.error('component.form.cascader', err);
                }
                this.code.formatDataSource(this, this.code_data.data_source);
            }
        },
        handle_click() {
            // 判定是否设定了其他操作
            if (!this.code_data.parameters?.is_other_click) return false
            //console.log('handle cilck');

            // 当前仅支持 选择完做 loading  效果，具体需求再调整
            // 后期可做成键值对的形式 兼容更多的模式

            let that = this;
            this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            setTimeout(() => {
                that.$loading().close();
            }, 1000)
        }
    }
}
</script>