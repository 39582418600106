<template>
  <div>
    <div class="register d-flex justify-content-center align-items-end"
      :style="{ backgroundImage: 'url(' + bjimg + ')' }">
      <div class="registerbox clearfloat">
        <div class="registeright">
          <div v-if="noLoginGlobal['system.base.setting'] &&
            noLoginGlobal['system.base.setting']['backend_info'] &&
            noLoginGlobal['system.base.setting']['backend_info'][
            'platform.backend.prospect_url'
            ]
            ">
            <img :src="noLoginGlobal['system.base.setting'] &&
              noLoginGlobal['system.base.setting']['backend_info'] &&
              noLoginGlobal['system.base.setting']['backend_info'][
              'platform.backend.prospect_url'
              ]
              " alt="" />
          </div>
        </div>
        <!-- <div class="registeright"><img src="../../assets/images/registerimg.png" alt=""></div> -->
        <div class="registerleft">
          <!-- 暂时隐藏 误删 -->
          <div class="registertop">
            <div :class="{ active: 1 == num }" @click="table(0)">
              <img src="../../assets/images/mobile.svg" alt="" class="regmobile" />
              <div class="regtip regtiper">
                <img src="../../assets/images/usered.svg" alt="" style="vertical-align: -6px" />
                <span>账号密码登录</span>
              </div>
            </div>
            <div :class="{ active: 0 == num }" @click="table(1)">
              <img src="../../assets/images/logonewm.svg" alt="" class="regscan" />
              <div class="regtip">
                <img src="../../assets/images/qywx.svg" alt="" style="vertical-align: -4px" />
                <span>企业微信扫码登录</span>
              </div>
            </div>
          </div>
          <div class="registercon">
            <div class="registerlogo" v-if="noLoginGlobal['system.base.setting'] &&
              noLoginGlobal['system.base.setting']['backend_info'] &&
              noLoginGlobal['system.base.setting']['backend_info'][
              'platform.backend.login_logo_url'
              ]
              ">
              <img :src="noLoginGlobal['system.base.setting'] &&
                noLoginGlobal['system.base.setting']['backend_info'] &&
                noLoginGlobal['system.base.setting']['backend_info'][
                'platform.backend.login_logo_url'
                ]
                " alt="" />
            </div>
            <!-- <div class="registerlogo"><img src="../../assets/images/logos.svg" alt=""></div> -->
            <div class="registerconter">
              <div class="rebateboxcon" v-if="psw">
                <div class="rebateboxlist">
                  <ul>
                    <li>
                      <span><img src="../../assets/images/user.svg" alt="" /></span>
                      <input type="text" ref="name" v-model="name" maxlength="32" placeholder="账户名/手机号" />
                    </li>
                    <li>
                      <span><img src="../../assets/images/pass.svg" alt="" /></span>
                      <input :type="pswcon ? 'password' : 'text'" ref="password" v-model="password" maxlength="32"
                        placeholder="请输入密码" />
                      <strong>
                        <img :src="pswcon ? closepass : openpass" @click="pswcon = !pswcon" />
                      </strong>
                    </li>
                    <li class="rebateboxlast">
                      <button @click="passwordLogin">登录</button>
                    </li>
                  </ul>
                </div>
              </div>
              <template v-else>
                <div class="rebateboxcon">
                  <div class="regewm">
                    <div class="regewmimg">
                      <img src="../../assets/images/bigewm.svg" alt="" />
                    </div>
                    <p>企业微信扫一扫更方便</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <p class="registerlast" v-if="noLoginGlobal['system.base.setting'] &&
        noLoginGlobal['system.base.setting']['backend_info'] &&
        noLoginGlobal['system.base.setting']['backend_info'][
        'platform.backend.copyright_info'
        ]
        ">
        {{
          noLoginGlobal["system.base.setting"] &&
          noLoginGlobal["system.base.setting"]["backend_info"] &&
          noLoginGlobal["system.base.setting"]["backend_info"][
            "platform.backend.copyright_info"
          ]
        }}
      </p>
    </div>

    <!-- 图片验证 -->
    <div class="ttshop_yanzheng" v-show="verify">
      <div class="ttshop_yanzhengbj"></div>
      <div class="ttshop_yanzhengbox">
        <slide-verify ref="slideblock" @success="onSuccess" @again="onAgain" @fulfilled="onFulfilled" @fail="onFail"
          @refresh="onRefresh" :slider-text="text" :imgs="imgs" :accuracy="accuracy"></slide-verify>
        <div class="ttshop_yanzhengclose" @click="closeVerify">
          <img class="img-default" src="../../assets/images/ttshop_colse.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import img0 from "../../assets/images/img.jpg";
import img1 from "../../assets/images/img1.jpg";
import img2 from "../../assets/images/img2.jpg";
import img3 from "../../assets/images/img3.jpg";
import img4 from "../../assets/images/img4.jpg";
import img5 from "../../assets/images/img5.jpg";
export default {
  data() {
    return {
      num: 0,
      psw: true,
      pswcon: true,
      bjimg: require("../../assets/images/registerbanner.png"), //背景图
      closepass: require("../../assets/images/closepass.svg"), //背景图
      openpass: require("../../assets/images/openpass.svg"), //背景图
      verify: false,
      imgs: [img0, img1, img2, img3, img4, img5],
      accuracy: 5, // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      msg: "",
      text: "向右滑动->",
      name: "", //账户名/手机号
      password: "", //密码
    };
  },
  components: {},
  computed: {
    ...mapState(["noLoginGlobal"]),
  },
  watch: {
    noLoginGlobal: {
      handler(newVal, oldVal) {
        //
        this.bjimg =
          (newVal["system.base.setting"] &&
            newVal["system.base.setting"]["backend_info"] &&
            newVal["system.base.setting"]["backend_info"][
            "platform.backend.background_url"
            ]) ||
          require("../../assets/images/registerbanner.png");
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    let that = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key === 13) {
        that.passwordLogin();
      }
    };
  },
  mounted() { },
  methods: {
    table(index) {
      this.num = index;
      this.psw = !this.psw;
    },
    //账号密码登录
    passwordLogin() {
      let _this = this;
      let name = this.$refs.name.value;
      let password = this.$refs.password.value;
      if (name == "") {
        this.common.message(this, "请输入用户名");
      } else if (password == "") {
        this.common.message(this, "请输入密码");
      } else {
        this.verify = true;
      }
    },
    paddwordapi() {
      let _this = this;
      let name = this.$refs.name.value;
      let password = this.$refs.password.value;
      let data = {
        passport: name,
        password: password,
        login_type: 1,
      };
      this.$post(this.$apis.pcLogin, data)
        .then((res) => {
          if (res.code == 200) {
            _this.getjurisdiction()
            this.$store.commit("userInfo", res.data);
            const redirect = this.$route.query.redirect || "/";
            this.$router.replace(redirect);
          } else {
            this.common.message(this, res.message);
          }
        })
        .catch((err) => {
          console.log(err);
          this.common.message(this, err.message);
        });
    },
    //关闭图形验证
    closeVerify() {
      this.verify = false;
    },
    // 图形验证
    onSuccess(times) {
      this.msg = `login success, 耗时${(times / 1000).toFixed(1)}s`;
      this.verify = false;
      this.paddwordapi();
      this.handleClick();
    },
    onFail() {
      // console.log("验证不通过");
      this.msg = "";
    },
    onRefresh() {
      // console.log("点击了刷新小图标");
      this.msg = "";
    },
    onFulfilled() {
      // console.log("刷新成功啦！");
    },
    onAgain() {
      // console.log("检测到非人为操作的哦！");
      this.msg = "try again";
      // 刷新
      this.handleClick();
    },
    handleClick() {
      this.$refs.slideblock.reset();
      this.msg = "";
    },
    // 获取登录者的权限
    getjurisdiction() {
      let that = this;
      let data = {
        keys: ["admin.permissions"],
      };
      this.common.getCommonInfo(this, data, function() {
        that.$store.commit("admin_permissions", that.common_info['admin.permissions']);
      });
    },
  },
};
</script>
<style scoped>
.register {
  width: 100%;
  height: 100vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.registerbox {
  width: 900px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -450px;
  height: 450px;
  background: #fff;
  margin-top: -225px;
  border-radius: 6px;
  display: flex;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.registerleft {
  width: 500px;
  /* float: right; */
  position: relative;
}

.registertop {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 0 6px 0 0;
  display: none;
}

.registertop>div {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  width: 80px;
  height: 80px;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s;
  border-radius: 0 6px 0 0;
}

.registertop>div>img {
  display: block;
  max-width: 100%;
  border-radius: 0 6px 0 0;
}

.registertop>div.active {
  opacity: 1;
  z-index: 1;
}

.regtip {
  border-radius: 4px;
  background: #007af0;
  box-sizing: border-box;
  position: absolute;
  top: -45px;
  right: 0;
  width: 154px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}

.regtiper {
  width: 130px;
}

.regtip::before {
  content: "";
  width: 0;
  height: 0;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  border-width: 6px;
  border-style: solid dashed dashed dashed;
  border-color: #007af0 transparent transparent transparent;
  position: absolute;
  bottom: -12px;
  right: 19%;
}

.regtip img {
  width: 22px;
  margin-right: 4px;
  margin-top: 0;
  display: inline-block;
}

.regtip span {
  font-size: 14px;
  color: #fff;
  display: inline-block;
}

.registercon {
  width: 100%;
  padding: 64px 80px 0 80px;
  box-sizing: border-box;
}

.registerlogo {
  max-width: 256px;
  margin: 0 auto 45px;
}

.registerlogo img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  object-fit: cover;
}

.registerconter {
  width: 100%;
}

.rebateboxcon {
  width: 100%;
}

.rebateboxcon h5 {
  font-size: 24px;
  color: #333;
  text-align: center;
  font-weight: 500;
  margin-bottom: 32px;
}

.rebateboxlist {
  width: 100%;
}

.rebateboxlist ul li {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.rebateboxlist ul li span {
  position: absolute;
  top: 10px;
  left: 14px;
  width: 20px;
}

.rebateboxlist ul li span:before {
  content: "";
  width: 1px;
  height: 20px;
  background: #dddfe5;
  position: absolute;
  top: 0;
  right: -12px;
}

.rebateboxlist ul li span img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.rebateboxlist ul li strong {
  position: absolute;
  top: 10px;
  right: 14px;
  width: 20px;
  height: 20px;
}

.rebateboxlist ul li strong img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  cursor: pointer;
  height: 20px;
}

.rebateboxlist ul li:last-child {
  margin-bottom: 0;
}

.rebateboxlist ul li input {
  width: 100%;
  height: 40px;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 0 42px 0 61px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  outline: none;
  transition: all 0.3s;
}

.rebateboxlist ul li input:focus {
  border-color: #1c66ff;
}

.rebateboxlist ul li.rebateboxlast {
  margin-top: 56px;
}

.rebateboxlist ul li.rebateboxlast button {
  width: 100%;
  height: 40px;
  border: 0;
  border-radius: 2px;
  display: block;
  background: #007af0;
  font-size: 14px;
  color: #fff;
  outline: none;
}

.regewm {
  width: 100%;
}

.regewmimg {
  width: 158px;
  height: 158px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 auto 20px;
}

.regewmimg img {
  display: block;
  width: 100%;
  border-radius: 4px;
}

.regewm p {
  font-size: 14px;
  color: #333;
  text-align: center;
}

input[type="password"]::-webkit-input-placeholder {
  color: #999;
}

input[type="password"]:-moz-placeholder {
  color: #999;
}

input[type="password"]::-moz-placeholder {
  color: #999;
}

input[type="password"]:-ms-input-placeholder {
  color: #999;
}

.registeright {
  min-width: 400px;
  width: 400px;
  /* float: left; */
  border-radius: 6px 0 0 6px;
}

.registeright div {
  width: 100%;
  height: 100%;
}

.registeright div img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px 0 0 6px;
}

.registerlast {
  width: 100%;
  font-size: 12px;
  color: #999;
  font-weight: 400;
  text-align: center;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.ttshop_yanzheng {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.ttshop_yanzhengbox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
}

.ttshop_yanzhengclose {
  height: 12px;
  width: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.ttshop_yanzhengbj {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
</style>