<template>
	<div class="header clearfloat">
		<div class="headerleft">
			<a href="javascript:void(0);" @click="headerlogo()" class="clearfloat">
				<img :src="noLoginGlobal['system.base.setting'] && noLoginGlobal['system.base.setting']['backend_info'] && noLoginGlobal['system.base.setting']['backend_info']['platform.backend.backend_logo_url']"
					alt=""
					v-if="noLoginGlobal['system.base.setting'] && noLoginGlobal['system.base.setting']['backend_info'] && noLoginGlobal['system.base.setting']['backend_info']['platform.backend.backend_logo_url']" />
				<!-- <img src="../assets/logo.png" alt="" /> -->
				<!-- <span>TTSHOP新零售电商系统</span> -->
			</a>
		</div>
		<div class="headernav" v-if="headerstyle">
			<ul class="clearfloat">
				<li v-for="(item, index) in menuLists.base" :key="item.id"
					:class="isChangeRoute(item.route) ? 'active' : ''"
					@click="handleRouteLink(item.permission_name, item.children, item.route, item.group)">
					<a href="javascript:void(0)"><i :class="item.icon"></i>{{ item.permission_name }}</a>
				</li>
			</ul>
		</div>
		<div class="headeright clearfloat">
			<a href="javascript:void(0);" @click="clearCache" class="headerbut"><i class="el-icon-delete-solid"></i>
				清理缓存</a>
			<a href="javascript:void(0);" v-if="this.openurl" class="headerbut" @click="openpcshop()"><i
					class="el-icon-s-goods"></i> PC商城</a>
			<a href="javascript:void(0);" @click="goMessageCenter" class="headerbut"><i class="el-icon-message-solid"></i>
				消息中心</a>
			<div class="headersing" @click="headerslide = !headerslide">
				<a href="javascript:void(0);">管理员:<span>{{ username ? username : 'user' }}</span><i
						class="el-icon-caret-bottom"></i></a>
				<el-collapse-transition>
					<div class="headerslide" v-show="headerslide" @mouseleave="headout">
						<a href="javascript:void(0);" @click="handleModifyPwd">修改密码</a>
						<a href="javascript:void(0);" @click="quit()">退出登录</a>
					</div>
				</el-collapse-transition>
			</div>
		</div>
		<!--修改密码dialog -->
		<el-dialog title="修改密码" :visible.sync="pwdDialog" width="550px">
			<el-form label-width="100px" :model="passwordInfo" :rules="rules" ref="pwdForm">
				<el-form-item label="旧密码：" prop="old_password">
					<el-input type="password" v-model="passwordInfo.old_password" placeholder="请输入旧密码" class="addpwd"></el-input>
				</el-form-item>
				<el-form-item label="新密码：" prop="new_password">
					<el-input type="password" v-model="passwordInfo.new_password" placeholder="请输入新密码" class="addpwd"></el-input>
				</el-form-item>
				<el-form-item label="确认密码：" prop="confirm_password">
					<el-input type="password" v-model="passwordInfo.confirm_password" placeholder="请输入确认密码" class="addpwd"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<div slot="footer" class="dialog-footer dialogadd">
					<el-button @click="handleCancel">取 消</el-button>
					<el-button @click="handleSubmit" type="primary">确 定</el-button>
				</div>
			</span>

		</el-dialog>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { mapState, mapGetters } from "vuex";
export default {
	name: "header_head",
	data() {
		return {
			headerstyle: false,
			headerslide: false,
			pwdDialog: false,
			openurl: '',
			username: '',
			passwordInfo: {
				old_password: "",//旧密码
				new_password: "", //新密码
				confirm_password: "",//确定密码
			},
			rules: {
				old_password: [{ required: true, message: "请输入旧密码!", trigger: 'blur' }, { min: 6, max: 30, message: "密码长度在6-30位!", trigger: 'blur' }],
				new_password: [{ required: true, message: "请输入新密码!", trigger: 'blur' }, { min: 6, max: 30, message: "密码长度在6-30位!", trigger: 'blur' }],
				confirm_password: [{ required: true, validator: this.confirmPwdValid, trigger: 'blur' }],
			},
			badgeIsShow: false
		};
	},
	computed: {
		...mapGetters(['menuLists']),
		...mapState(["noLoginGlobal"])
	},
	watch: {
		"noLoginGlobal": {
			handler(newVal, oldVal) {
				this.openurl = newVal['default.config.list'] && newVal['default.config.list']['pc.default.url'];
			},
			deep: true,
			immediate: true,
		}
	},
	mounted() {
		let name = this.$store.state.user?this.$store.state.user.username:'';
		let styletype = window.localStorage.getItem("style");
		this.headerstyle = styletype == 1 ? true : false;
		this.username = name
	},
	methods: {
		headerlogo() {
			this.$router.push({ path: "/" });
		},
		openpcshop() {
			if (this.openurl) {
				window.open(this.openurl);
			}
		},
		isChangeRoute(route) {
			if (this.$route.fullPath.lastIndexOf("/") != this.$route.fullPath.indexOf("/")) {
				let subString = this.$route.fullPath.substring(0, this.$route.fullPath.lastIndexOf("/"));
				if (subString.indexOf("/") != subString.lastIndexOf("/")) { //判断多级
					subString = subString.substring(0, subString.lastIndexOf("/"));
					return route == subString;
				}
				return route == subString;
			} else {
				return this.$route.fullPath == route;
			}
		},

		handleRouteLink(title, children, route, group) {
			// 自定义事件，用于动态渲染二级页面导航栏
			this.$emit("handleRouteLink", title, children);
			// 修改menuType
			this.$store.commit("SET_MENU_TYPE", group);
			this.$router.push(route)
		},

		// 确定密码校验
		confirmPwdValid(rule, value, callback) {
			const reg = /[a-zA-Z0-9]{6,30}/
			if (value == "") {
				callback(new Error("请输入确认密码!"));
			}
			if (!reg.test(value)) {
				callback(new Error("密码长度在6-30位!"));
			}
			if (value != this.passwordInfo.new_password) {
				callback(new Error("确认密码与新密码不一致!"));

			}
			callback();
		},
		// 清除缓存
		clearCache() {
			console.log('this', this);
			this.$put(this.$apis.clearCache, {
				'data_clear': 1,
				'file_clear': 0
			}).then(res => {
				if (res.code == 200) {
					this.$message.success('清除成功')
				}
			})
		},
		// 获取是否有未读消息
		getIsreadMsg() {
			let data = {
				search: [{
					"key": "is_read",
					"value": 0,
					"op": "equal"
				}]
			}
			this.$get(this.$apis.noticeList, data).then(res => {
				if (res.code == 200) {
					this.badgeIsShow = res.data.list.length > 0
				}
			})
		},
		goMessageCenter() {
			// 去消息中心
			this.$router.push('/feedback/message')

		},
		//退出
		quit() {
			this.common.signout(this);
		},
		headover() {
			this.headerslide = true
		},
		headout() {
			this.headerslide = false
		},
		// 修改密码
		handleModifyPwd() {
			this.pwdDialog = true
		},
		handleClose() { },
		handleCancel() {
			this.$refs['pwdForm']['resetFields']();
			this.pwdDialog = false;
		},
		handleSubmit() {
			this.$refs['pwdForm']['validate'](valid => {
				if (valid) {
					this.$put(this.$apis.modifyPwd, this.passwordInfo).then(res => {
						if (res.code == 200) {
							Message.success({
								message: "修改密码成功!",
								duration: 1500,
								onClose: () => {
									// 重新登录
									this.quit();
									this.handleCancel();
								}
							});
						} else {
							Message.error(`修改密码失败!-[${res.message}]`);
						}
					})
				} else {
					Message.error("页面信息有误，请检查后提交!")
				}
			})
		}
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// /deep/.dialogadd .addressqr.el-button.el-button--default:hover:not(.el-button--primary):hover{color: #fff !important;}
.addpwd{width: 100%;}
.headernav {
    float: left;
    margin-left: 40px;
}

.headernav ul li {
    float: left;
    margin-right: 12px;
    line-height: 47px;
}

.headernav ul li:last-child {
    margin-right: 0;
}

.headernav ul li a {
    display: block;
    font-size: 14px;
    color: #fff;
    transition: all 0.3s;
    border-bottom: 3px solid hsla(0, 0%, 100%, 0);
    box-sizing: border-box;
    padding: 0 6px;
}

.headernav ul li a i {
    margin-right: 4px;
}

.headernav ul li.active a {
    border-color: var(--navFontActive, #fb6638);
    color: var(--navFontActive, #fb6638);
    background: var(--navFontActiveBg, transparent);
}

.headernav ul li:hover a {
    border-color: var(--navFontActive, #fb6638);
    color: var(--navFontActive, #fb6638);
    background: var(--navFontActiveBg, transparent);
}

.header {
    width: 100%;
    position: fixed;
    background: var(--header, #1e222b);
    height: 50px;
    z-index: 2200;
    padding: 0 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
}

.headerleft {
    float: left;
    padding-top: 7px;
    box-sizing: border-box;
}

.headerleft a {
    display: block;
}

.headerleft a img {
    float: left;
    width: auto !important;
	border-radius: 0 !important;
	margin-right: 0 !important;
    height: 32px !important;
}

.headerleft a span {
    float: left;
    font-size: 16px;
    color: #fff;
    line-height: 36px;
}

.headeright {
    float: right;
}

.headeright .headerbut {
    float: left;
    font-size: 14px;
    color: #fff;
    padding: 0 12px;
    box-sizing: border-box;
    line-height: 50px;
    transition: all 0.3s;
}

.headeright .headerbut:hover {
    background: rgba(255, 255, 255, 0.1);
}

.headersing {
    float: left;
    position: relative;
}

.headersing>a {
    font-size: 14px;
    color: #fff;
    padding: 0 12px;
    box-sizing: border-box;
    line-height: 50px;
    display: block;
    transition: all 0.3s;
}

.headersing>a:hover {
    background: rgba(255, 255, 255, 0.1);
}

.headerslide {
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    padding: 6px 0;
    box-sizing: border-box;
    background: var(--fontColor, #3444ff);
    border-radius: 0 0 4px 4px;
    /* display: none; */
}

.headerslide a {
    display: block;
    width: 100%;
    padding: 14px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #fff;
    transition: all 0.3s;
}

.headerslide a:hover {
    background: rgba(255, 255, 255, 0.1);
}
.headerleft a{margin-left: 14px;}
.header {
	min-width: 1200px;
}

/* 设置过渡，只过渡maxheight */
.fade-leave-active,
.fade-enter-active {
	transition: max-height 0.5s;
}

/* 使其动作enter后和leave前，高度为0 */
.fade-enter,
.fade-leave-to {
	max-height: 0px;
}

/* 使其enter前和leave后，高度为100px */
.fade-enter-to,
.fade-leave {
	max-height: 100px;
}

/deep/.el-dialog__body {
	padding: 20px 50px 20px 20px !important;
	box-sizing: border-box;
}

.badge-box {
	position: relative;
}

.badge-item {
	right: 0;
	position: absolute;
}
</style>
