<template>
    <el-card :ref="component_id" style="overflow: initial;">
        <!-- 需要挂载的外部弹窗组件 -->
        <div v-for="(item, index) in code_data.other_dialog" :key="index">
            <!-- 导入 -->
            <diy-import-more ref="diyImportMore" :dialogTitle="item.dialog_title" v-if="item.type == 'import'"
                :dialogImportUrl="item.dialog_import_url" :dialogTemplateUrl="item.dialog_template_url"
                @dialogImportCallback="importCallback"></diy-import-more>

            <!-- 导出 -->
            <diy-export-more ref="diyExportMore" :dialogTitle="item.dialog_title" v-if="item?.type == 'export'"
                :dialogExportKeys="item.dialog_export_keys" :dialogExportUrl="item.dialog_export_url"
                :dialogExportIsSearch="item.dialogExportIsSearch"></diy-export-more>
        </div>
        <!-- 需要挂载的自定义弹窗组件 -->
        <component v-for="(item, i) in code_data.dialog_dom" :is="item.type" :code_data="item" :$formData="childAndFather"
            :ref="item.ref"></component>
        <div class="global-search-single">
            <div class="single-left">
                <component v-for="(item, index) in code_data.top_buttons" :key="index" is="component.button"
                    :code_data="item" :code_this="code_this" :$formData="childAndFather">
                </component>
            </div>
            <div class="single-right">
                <div class="search-item" style="margin:0;">
                    <component v-for="(item, index) in code_data.components" :key="index" :$formData="formData"
                        :is="item.type" :code_data="item"></component>
                </div>
                <el-button type="primary" @click="search_submit" v-if="code_data.is_search">搜索</el-button>
                <component v-for="(item, index) in code_data.attach_buttons" :key="index" is="component.button"
                    :code_data="item">
                </component>
            </div>
        </div>
    </el-card>
</template>
<script>
export default {
    name: "component.single_search",
    props: {
        code_data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            component_id: this.code.domId(this.code_data),
            formData: {},
            keyMap: {},
            childAndFather: {},
            code_this: this
        }
    },
    watch: {
        code_data: {
            handler(newVal, oldVal) {
                console.log(newVal, oldVal, 'search  监听到了吗');
                this.init()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        init() {
            let formRules = {};
            let formData = {};
            let keyMap = {};
            for (let i in this.code_data.components) {
                let component = this.code_data.components[i];
                //数据格式化
                component.id = this.code.domId(component);
                if (component.data_source && component?.key) {
                    component.data_source.successCallback = function(domThis, res) {
                        // component.data_source = res;
                        this.code.formatDataSource(this, component.data_source);
                    }
                }
                formRules[component.key] = component.rules;
                let _formdata = {
                    op: component.op,
                    value: this.code.formatDataValue(component, component?.default ?? ''),
                    key: component.op_select.default,
                    type: component.type,
                    label: component.label
                }
                formData[component.key] = _formdata;
                keyMap[component.key] = component;
            }
            // 需要处理成对象，因为子组件需要通过key直接操控父组件
            let childAndFather = {}
            for (let i in this.code_data.dialog_dom) {
                let dialog_key = this.code_data.dialog_dom[i].dialog_key;
                if (dialog_key) {
                    childAndFather[dialog_key] = false
                }
            }

            this.formRules = formRules
            this.formData = formData;
            this.childAndFather = childAndFather;

        },
        extractSearch() {
            let _data = {}
            if (this.$parent.$refs['component.table']) {
                const { currentPage, eachPage, sort_type, sort_prop } = this.$parent.$refs['component.table'][0]
                _data = {
                    search: [],
                    order: [{
                        key: sort_prop,
                        value: sort_type
                    }],
                    page: currentPage,
                    limit: eachPage
                };
            } else {
                _data = {
                    search: [],
                    order: [{
                        key: 'id',
                        value: 'desc'
                    }],
                    page: 1,
                    limit: 10
                };
            }

            let requestData = Object.keys(this.formData).map(item => this.formData[item])

            let _ansRequest = [];
            for (let i = 0; i < requestData.length; i++) {
                let ansRequest = {}

                // 如果为数组，两项都有值再提取数据
                if (Array.isArray(requestData[i].value) && !requestData[i].value[0] && !requestData[i].value[1]) continue;
                // 如果为单项值，有值再提取数据
                if (!requestData[i].value) continue;

                ansRequest['op'] = requestData[i].op
                ansRequest['key'] = requestData[i].key
                ansRequest['value'] = requestData[i].value
                if (requestData[i].type == 'component.search.dateRangeInput' && requestData[i].value[0] && requestData[i].value[1]) {
                    ansRequest['value'] = [this.common.timeStampTurnTime(requestData[i].value[0]), this.common.timeStampTurnTime(requestData[i].value[1])]
                }
                else if (requestData[i].type == 'component.search.intervalInput' && requestData[i].value[0] && requestData[i].value[1]) {
                    if (requestData[i].value[0] > requestData[i].value[1]) {
                        this.$message.warning(`${requestData[i].label}区间输入有误！`)
                        return false
                    }
                }
                _ansRequest.push(ansRequest)
            }
            _data.search = _ansRequest
            return _data
        },
        // 获取table数据
        getTableData() {
            this.$parent.$refs['component.table'][0].getTableData()
        },
        search_submit() {
            this.$parent.$refs['component.table'][0]['currentPage'] = 1
            this.$parent.$refs['component.table'][0]['eachPage'] = 10
            this.getTableData()
        },
        // 导入dialog的回调
        importCallback() {
            this.$parent.$refs['component.table'][0]['currentPage'] = 1
            this.$parent.$refs['component.table'][0]['eachPage'] = 10
            this.getTableData()
        },
    }
}
</script>
<style lang="scss" scoped>
.single-left {
    display: flex;
}
</style>