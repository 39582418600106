<!-- 
    参数详解
    - show-all-levels 是否展示完整路径
    - separator 选项分隔符
    - filterable 是否可搜索
    - clearable 是否可清空选项
    - placeholder 输入框占位符
    - disabled 是否禁用
 -->
<template>
    <div class="search-item">
        <label class="search-label">{{ code_data.label }}：</label>
        <el-cascader :append-to-body="false" :placeholder="code_data.parameters.placeholder || ''" clearable filterable
            :show-all-levels="code_data.parameters?.show_all_levels ?? true" :separator="code_data.parameters?.separator"
            :disabled="code_data.parameters?.is_disabled" v-model="$formData[code_data.key]['value']" :options="apiData"
            :props="{
                value: code_data.parameters.value,
                label: code_data.parameters.label,
                children: code_data.parameters.children
            }"></el-cascader>
    </div>
</template>
<script>
export default {
    name: "component.search.cascaderInput",
    props: {
        code_data: {
            typeof: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {
            apiData: []
        }
    },
    mounted() {
        // 获取级联数据
        if (this.code_data.data_source && this.code_data.data_source.type == 'Api') {
            this.code_data.data_source['successCallback'] = function(domThis, res) {
                if (domThis.code_data?.parameters?.is_filter) {
                    domThis.apiData = domThis.checkTreeData(res.data.list)
                } else {
                    domThis.apiData = res.data.list;
                }
                console.log(domThis.apiData, 'domThis.apiDatadomThis.apiData');
            }
            this.code_data.data_source['failCallback'] = function(domThis, err) {
                console.log('component.form.cascader', err);
            }
            this.code.formatDataSource(this, this.code_data.data_source);
        }
    },
    methods: {
        checkTreeData(data) {
            // 在级联选择器下，过滤的key为用户指定的children字段
            const filter_key = this.code_data.parameters.children;
            for (var i = 0; i < data.length; i++) {
                if (data[i][filter_key].length < 1) {
                    data[i][filter_key] = undefined;
                } else {
                    this.checkTreeData(data[i][filter_key]);
                }
            }
            return data;
        }
    }
}
</script>