import common from './common'

let dom_num = 0;

const deepClone = function(obj) {
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	let clone = Array.isArray(obj) ? [] : {};

	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			clone[key] = deepClone(obj[key]);
		}
	}

	return clone;
}

export default {
	/**
	 * Dom对象Id
	 * 
	 * @param {*} obj 
	 * @returns 
	 */
	domId: function(obj) {
		dom_num += 1;
		return obj?.id ?? obj.type + '_' + dom_num + '_' + (10000000000 + Math.floor(Math.random() * 10000000000))
	},
	/**
	 * 请求接口
	 * 
	 * @param {*} domThis 组件对象 含data传递数据
	 */
	request: function(domThis, api, requestData, successCallback, failCallback) {
		if (api) {
			if (!requestData) {
				requestData = {};
			}
			if (api.parameters) {
				let _obj = {}
				Object.keys(api.parameters).forEach(item => {
					_obj[item] = this.formatTemplate(domThis, api.parameters[item])
					if (requestData[item]) {
						// 考虑parameters的值有对象和数组两种情况，如有其它情况，请在往后续写
						if (Array.isArray(requestData[item]) && Array.isArray(_obj[item])) {
							requestData[item] = requestData[item].concat(_obj[item])
						} else if (Array.isArray(requestData[item]) && typeof _obj[item] === 'object') {
							requestData[item].push(_obj[item])
						} else if (typeof requestData[item] === 'object' && typeof _obj[item] === 'object') {
							requestData[item] = Object.assign({}, requestData[item], _obj[item])
						}
					} else {
						requestData[item] = _obj[item]
					}
				})
			}

			if (requestData?.search?.length) {
				requestData.search.forEach(item => {
					item['value'] = this.formatTemplate(domThis, item['value'])
					//console.log(item, 'itemitem');
				})
			}

			let url = this.formatTemplate(domThis, api.url);
			let apiFun = domThis.$get;
			if (api.method == 'GET') {
				apiFun = domThis.$get;
			} else if (api.method == 'POST') {
				apiFun = domThis.$post;
			} else if (api.method == 'PUT') {
				apiFun = domThis.$put;
			} else if (api.method == 'DELETE') {
				apiFun = domThis.$delete;
			}
			apiFun(url, requestData).then(res => {
				if (res.code == 200) {
					if (successCallback) {
						successCallback(domThis, res);
					}
				} else {
					domThis.$message.warning(res.message)
					if (failCallback) {
						failCallback(domThis, res)
					}
				}
			}).catch(err => {
				domThis.$message.error(err.message)
				if (failCallback) {
					failCallback(domThis, err)
				}
			});
		}
	},
	/**
	 * 数据源获取
	 * 
	 * @param {*} data 
	 * @returns 
	 */
	formatDataSource: function(domThis, data) {
		let result;
		if (data.type == 'Enum') {
			result = data.data;
			if (data.successCallback) {
				data.successCallback(domThis, result);
			}
		} else if (data.type == 'Resource') {
			result = this.formatTemplate(domThis, data.data);
			// console.log(result, 'resultresult');
			if (data.successCallback) {
				data.successCallback(domThis, result);
			}
		} else if (data.type == 'Api') {
			this.request(domThis, data.api, {}, data.successCallback, data.failCallback);
		}
		return result;
	},
	/**
	 * 数据值格式化
	 * 
	 * @params obj  当前层级的数据集合 Template(模板变量) / Integer(整型) / String(字符串) / date_time(时间格式化) / Array(数组) / Bool(布尔)
	 * @params value  需要处理的数据
	 * @params that  如果需要调用Template的时候  必须传this
	 * @returns 返回值，处理之后的返回值
	 */
	formatDataValue: function(obj, value, that = {}) {
		let _value = deepClone(value);
		if (obj?.format && _value) {
			obj.format.split(',').forEach(item => {
				if (item == 'Template') {
					_value = this.formatTemplate(that, _value);
				} else if (item == 'Integer') {
					_value = parseInt(_value);
				} else if (item == 'String') {
					_value = String(_value);
				} else if (item == 'date_time') {
					_value = common.timeStampTurnTime(_value, obj.format_value);
				} else if (item == 'Array') {
					_value = Array.from(_value);
				} else if (item == 'Bool') {
					_value = Boolean(_value);
				}
			})
		}
		return _value;
	},

	/**
	 * formatTemplate 解析低代码双花括号
	 * @params domThis 必传，用于接收低代码传过来的模板domThis
	 * @params template，必传，需要解析的低代码模板  格式 domThis.$routr.xxx/domThis.xxx
	 * @returns 处理完成之后的返回值
	 */
	formatTemplate: function(domThis, template) {
		let res = template;
		if (/^\{\{(.*?)\}\}$/.test(template)) {
			template = template.replace(/^\{\{(.*?)\}\}$/, "$1").trim();
			eval('res = ' + template + ';');
		}
		return res;
	},
	/**
	 * 格式化表单提交参数
	 * 
	 * @param {*} domThis 
	 * @param {*} keyMap 
	 * @param {*} formData 
	 * @returns 
	 */
	formatRequestData: function(domThis, data, keyMap, formData) {
		let requestData = {};
		for (let i in formData) {
			if (!keyMap[i]) {
				continue;
			}
			requestData[i] = this.formatDataValue(keyMap[i], formData[i]);
		}
		return requestData;
	},
	/**
	 * 格式化按钮操作
	 * 
	 * @param {*} domThis 
	 * @param {*} data 
	 */
	buttonAction: function(domThis, data, callback) {
		//跳转路由
		if (data.action == 'Redirect') {
			let url = this.formatTemplate(domThis, data.url);
			if (url.indexOf('http') != -1) {
				window.open(url)
			} else {
				// //console.log(url)
				let redirect = {
					path: url ?? '/'
				};
				if (data.parameters && data.parameterslenght > 0) {
					redirect['query'] = data.parameters;
				}
				domThis.$router.push(redirect);
			}

		} else if (data.action == 'Api' || data.action == 'Confirm') {
			//console.log(data.api);
			this.request(domThis, data.api, {}, function(domThis, res) {
				domThis.$message.success(res.message);
				if (callback && typeof callback === 'function') {
					callback(domThis, true)
				}
			});
		}
	},
	/**
	 * 加载通用资源
	 * 
	 * @param {*} obj 
	 */
	loadResources: function(obj, successCallback) {
		if (obj.code_data.resources) {
			for (let i in obj.code_data.resources) {
				let data = obj.code_data.resources[i];
				data['successCallback'] = function(domThis, res) {
					let resource = domThis.$store.state.common_info ?? {};
					let common_infoMerge = Object.assign(resource, res.data);
					domThis.$store.commit("commonInfo", common_infoMerge);
					if (successCallback) {
						successCallback(domThis);
					}
				}
				this.formatDataSource(obj, data);
			}
		}
	},
}

