import { Base64 } from "js-base64";
export default {
	// 获取登录状态
	loginType(_this) {
		_this.user = _this.$store.state.user;
		if (_this.$store.state.user) {
			if (_this.user.token == "") {
				_this.login = true;
			} else {
				_this.login = false;
			}
		} else {
			_this.login = true;
		}
	},
	// 获取公共资源（需要登陆）
	async getCommonInfo(_this, data, callback) {
		await _this.$get(_this.$apis.resource, data).then((res) => {
			if (res.code == 200) {
				let common_info = _this.$store.state.common_info;
				let common_infoMerge = Object.assign(common_info, res.data);
				_this.common_info = common_infoMerge;
				_this.$store.commit("commonInfo", common_infoMerge);
				if (callback) {
					callback();
				}
			}
		}).catch((err) => {
			// this.message(_this, err)
		});
	},
	async getAuthority(_this, data, callback) {
		let common_info = _this.$store.state.common_info;
		// console.log(common_info)
		await _this.$get(_this.$apis.resource, data).then((res) => {
			// console.log(res)
			if (res.code == 200) {
				// _this.common_info = res.data;
				_this.$store.commit(
					"admin_permissions",
					res.data["admin.permissions"]
				);
				if (callback) {
					callback();
				}
			}
		}).catch((err) => {
			// this.message(_this, err)
		});
	},
	// 进入个人中心
	toPersonal(_this) {
		_this.user = _this.$store.state.user;
		if (_this.$store.state.user) {
			if (_this.user.token != "") {
				_this.$router.push("/ttshop_user");
			} else {
				_this.$router.push("/signin");
			}
		} else {
			_this.$router.push("/signin");
		}
	},
	// 退出登录
	signout(_this) {
		_this.$post(_this.$apis.quit).then((res) => {
			window.localStorage.removeItem('userInfo');
			_this.$store.commit("userInfo", '');
			_this.user = "";
			_this.login = true;
			_this.$router.replace("/signin");
		}).catch((err) => {
			console.log(err.message);
			_this.common.message(_this, err.message);
		});
	},
	// 报错信息提示
	message(_this, error, callback) {
		_this.$loading().close();
		_this.$message({
			showClose: true,
			message: error,
			type: "warning",
			offset: 200,
			duration: 1000,
		});
		if (callback) {
			callback();
		}
	},
	//正确信息
	success(_this, msg, callback) {
		_this.$loading().close();
		_this.$message({
			showClose: true,
			message: msg,
			type: "success",
			offset: 200,
			duration: 1000,
		});
		if (callback) {
			callback();
		}
	},
	// 页面返回顶部
	backTop(time) {
		if (!time) {
			time = 5;
		}
		let timeFun = setInterval(() => {
			let scroll_top = document.documentElement.scrollTop || document.body.scrollTop;
			let ispeed = Math.floor(-scroll_top / 5);
			document.documentElement.scrollTop = document.body.scrollTop = scroll_top + ispeed;
			// this.isTop = true;
			if (scroll_top === 0) {
				clearInterval(timeFun);
			}
		}, time)
	},
	webService(_this, goods, store, type, customer_service) {
		let url_str = '';
		if (store.option) {
			url_str = decodeURIComponent(store.option.web_service_url);
		}
		if (type == 'mch') {
			url_str = decodeURIComponent(customer_service)
		}
		let user_info = _this.$store.state.user;
		let access_token = _this.$store.state.user.access_token;
		url_str = url_str.replace("visiter_id=", "visiter_id=" + user_info.id)
		url_str = url_str.replace("visiter_name=", "visiter_name=" + user_info.nickname)
		url_str = url_str.replace("avatar=", "avatar=" + user_info.avatar_url)
		let mobile_url = '';
		let pc_url = '';
		let base_access_token = ""; //加密后的access_token
		let mch_id = 0;
		if (goods.id) {
			if (store.service_mobile) {
				mobile_url = encodeURIComponent(store.service_mobile + '/#/pages/goods/goods?id=' + goods.id)
			}
			if (store.service_pc) {
				pc_url = encodeURIComponent(store.service_pc + '/#/pages/goods/goods?id=' + goods.id)
			}
			let img = "";
			if (goods.pic_list) {
				if (goods.pic_list[0]) {
					img = goods.pic_list[0].pic_url
				}
			} else {
				if (goods.goods_pic_list && goods.goods_pic_list.length) {
					img = goods.goods_pic_list[0]
				}
			}
			base_access_token = Base64.encode(access_token);
			if (goods.mch) {
				mch_id = goods.mch.id;
			}
			let product = JSON.stringify({
				pid: goods.id,
				title: goods.name,
				img: img,
				info: goods.subtitle,
				price: goods.min_price,
				url: mobile_url,
				mobile_url: mobile_url,
				pc_url: pc_url,
				access_token: base_access_token,
				mch_id: mch_id
			});
			url_str += "&product=" + product
		}
		return url_str;
	},

	// 对于后台条件搜索进行封装
	/**
	 * searchObjName：操作条件搜索结果对象的名称
	 * searchObj：条件搜索结果对象：searchObj:{search: [],order: [{"key": "created_at","value": "desc"}],page:1,limit:10}
	 * search:{} 对应着el-form：model属性格式
	 * type:除了op==between格式外，其他格式可以选定搜索类型，默认为equal(全等查询)
	 */
	// 条件搜索函数
	handleSearch(search, type) {
		const entriesResult = Object.entries(search);
		const filterResult = entriesResult.filter(item => (item[1].length != 0 && Object.values(item[1]).every(item => (item != "" && item != null))));
		// 需要判断哪些搜索项要传递为数组格式，并将其过滤出来
		// 筛选出op为between的数据
		const betArrInfo = filterResult.reduce((pre, item) => {
			if ((item[1] && (typeof item[1] != null || typeof item[1] != undefined) && (Object.prototype.toString.call(item[1]).includes("Object")))) {
				pre.push(item)
			}
			return pre;
		}, [])
		// 整合op为between/equal的数据
		const betweenArr = betArrInfo.reduce((pre, item) => {
			if (!Object.keys(item[1]).includes('type')) {
				const valueArr = Object.values(item[1]);
				const obj = {}
				obj['value'] = valueArr;
				obj['key'] = item[0];
				obj['op'] = "between";
				pre.push(obj)
				return pre;
			} else {
				if (item[1].type == 'selectSearch') {
					const obj = {
						'op': item[1].op || 'equal'
					};
					for (const key in item[1]) {
						switch (key) {
							case 'option':
								obj['key'] = item[1][key];
								break;
							case 'value':
								obj['value'] = item[1][key];
								break;
						}
					}
					pre.push(obj);
					return pre;
				} else {
					throw new Error('The type field cannot be empty!');
				}
			}
		}, [])
		// 将结果赋值 this.searchObj.search
		// 筛选出op为search的数据
		const seaArrInfo = filterResult.reduce((pre, item) => {
			if (item[1] && (typeof item[1] != null || typeof item[1] != undefined) && (!(Object.prototype.toString.call(item[1]).includes('Object')))) {
				pre.push(item)
			}
			return pre;
		}, [])
		// 整合op为search的数据
		const searchArr = seaArrInfo.reduce((pre, item) => {
			const obj = {}
			obj['value'] = item[1];
			obj['key'] = (item[0] != 'keyword') ? item[0] : "keyword";
			obj['op'] = (item[0] != 'keyword') ? (Array.isArray(item[1]) ? "between" : (type ? type : "equal")) : "search";
			pre.push(obj)
			return pre;
		}, [])
		// 将结果返回
		// return searchObj.search = Array.of().concat(betweenArr).concat(searchArr)
		return Array.of().concat(betweenArr).concat(searchArr)

	},
	/**
	 * 重置条件搜索框
	 * search:{}
	 */
	handleClear(search) {
		const newSearch = {};
		for (const key in search) {
			// 判断
			if (typeof search[key] == "object") {
				if (!Array.isArray(search[key])) {
					const result = Object.entries(search[key]).reduce((pre, item) => {
						if (item[0] != 'type') {
							item[1] = "";
						}
						pre.push(item);
						return pre;
					}, [])
					const r = Object.fromEntries(result);
					newSearch[key] = r;
				} else {
					newSearch[key] = [];
				}
			} else {
				newSearch[key] = '';
			}
		}
		return newSearch;
	},
	/**
	 * 将对象转为数组
	 * @param {} source 对象类型的资源
	 * @param {} label 指定label属性名，默认为label
	 * @param {} value 指定value属性名，默认为value
	 */
	mapSource2List(source, label = 'label', value = 'value') {
		// 判断是否是对象
		if (!Object.prototype.toString.call(source).includes("Object")) {
			throw new Error(`Source is not an Object type!`);
		}
		return Object.entries(JSON.parse(JSON.stringify(source))).reduce((pre, item) => {
			const obj = {};
			obj[label] = item[1];
			obj[value] = item[0];
			pre.push(obj);
			return pre;
		}, [])
	},

	/**
	 * 判断父级是否存在子级并控制层级
	 * @param {*} arr cascader 数据数组
	 * @param {*} flag 是否能够操作最后一个子项
	 * @param {*} astrict 第几级受到控制
	 * @param {*} level 父级默认的level,默认为 1
	 * @param {*} levelArr 手机level数组
	 * @returns 
	 */
	changeCascaderSource(arr, flag, astrict = null, level = 1, levelArr = []) {
		// 将每次的levelpush到数组，找到最小值，进行限制
		levelArr.push(level);
		// 数据只展示到第二级别，需要像数据中添加level字段进行标识，以达到不允许在二级后操作
		// 需要判断是否有子集，是否展示展开叶子标识
		return arr.map(item => {
			// 像第一级别添加level
			// item.level = level ? ++level : ++item.pid; //只针对当前业务，可以手动传参
			item.level = level;
			// item.leaf = level >= tier; // 控制层级
			// 判断是否有子集并添加数据
			if (item.hasOwnProperty('children') && item.children.length != 0) {
				item['hasChildren'] = true;
				// 需要循环子集添加level
				let newLevel = item.level; //防止影响原数据
				this.changeCascaderSource(item.children, flag, astrict, ++newLevel, levelArr);
			} else {
				item['hasChildren'] = false;
				delete item.children; //需删除children，否则会出现空白页
				const lowLevel = new Set(levelArr); //去重				
				// 找最小值--Math.min
				const result = Math.min(...lowLevel);
				// 根据业务需求，是否能操作最后一个子项
				((item.level != result && (astrict && item.level == astrict)) && (item.disabled = flag));
			}

			return item;
		})
	},
	/**
	 * 任何时间  处理成 想要的格式
	 * 10位的时间戳，13位的时间搓，或者其他格式
	 */
	timeStampTurnTime(dateTime, fmt = 'yyyy-MM-dd hh:mm:ss') {
		// 如果为null,则格式化当前时间
		if (!dateTime) dateTime = Number(new Date());
		// 如果dateTime长度为10且10位都是纯数字，那么就是10位的时间戳
		let regularValue = /^\d{10}$/;
		let timestamp;
		if (regularValue.test(dateTime)) {
			dateTime *= 1000;
			timestamp = new Date(Number(dateTime));
		} else {
			timestamp = new Date(dateTime)
		}

		const weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];

		var o = {
			"M+": timestamp.getMonth() + 1, //月份 
			"d+": timestamp.getDate(), //日 
			"h+": timestamp.getHours(), //小时 
			"m+": timestamp.getMinutes(), //分 
			"s+": timestamp.getSeconds(), //秒 
			"q+": Math.floor((timestamp.getMonth() + 3) / 3), //季度 
			"S": timestamp.getMilliseconds(), //毫秒 
			"x+": weekdays[timestamp.getDay()]
		};

		if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (timestamp.getFullYear() + "").substr(4 - RegExp.$1.length));
		for (var k in o)
			if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((
				"00" + o[
				k]).substr(("" + o[k]).length)));
		return fmt;
	}
}
