<template>
	<div>
		<div class="table_head d-flex align-items-center">
			<template v-if="tableTopBtn.is_show">
				<el-checkbox v-model="all_checked" @change="allchange"></el-checkbox>
				<p class="page_num">当前页全选</p>
				<template v-for="(item, index) in tableTopBtn.btn">
					<el-button class="grounding" :key="index" @click.stop="tableFootClick(item)">{{ item.name }}
					</el-button>
				</template>
				<el-cascader v-model="tableTopBtn.cascadervalue" class="foot_cascader"
					:options="tableTopBtn.cascoptions" @change="cascChange"></el-cascader>
			</template>
		</div>
		<el-table :data="tableData" style="width: 100%" :stripe="tableTitle.stripe" :border="tableTitle.border"
			:fit="tableTitle.fit" :highlight-current-row="tableTitle.highlightCurrentRow"
			@selection-change="handleSelectionChange" ref="multipleTable">
			<!--表格第一列-->
			<el-table-column align="left" :type="firstTableCol.type" :min-width="firstTableCol.width" v-if="firstTableCol.select">
			</el-table-column>
			<!--表格其它列-->
			<el-table-column v-for="(value, index) in tableCol" :key="index" :prop="value.prop" :label="value.label"
				:min-width="value.width || 180" :sortable="value.isSort || false">
				<template slot-scope="scope">
					<template v-if="!value.render">
						<template v-if="value.formatter">
							{{ value.formatter(scope.row, value) }}
						</template>
						<template v-else-if="value.getImgurl">
							<!-- 图片放大效果 -->
							<!-- <el-image :src="value.getImgurl(scope.row, value)" style="width: 20px; height: 20px"
								:preview-src-list="value.previewSrcList ? value.previewSrcList(scope.row, value) : value.getImgurl(scope.row, value).split(',')" /> -->
							<el-image :src="value.getImgurl(scope.row, value)" style="width: 20px; height: 20px" />
						</template>
						<template v-else-if="value.isSwitch">
							<el-switch v-model="scope.row.putaway" active-color="#1890ff" inactive-color="#bbb">
							</el-switch>
						</template>
						<template v-else-if="value.isShop">
							<div class="goods_detail d-flex align-items-center">
								<div class="goods_detail_img">
									<img :src="scope.row.cover_pic" alt="" />
								</div>
								<div class="table_name" v-if="value.isident">
									<span>{{scope.row.ident == 1 ? "普" : scope.row.ident == 2 ? "卡" : "虚" }}</span>
									{{ scope.row.name }}
								</div>
								<div class="table_iconed">
									<el-popover placement="top" width="515" trigger="click"
										:ref="'nameVisible' + scope.row.id">
										<div class="popover">
											<div class="popover_wrap d-flex align-items-center">
												<div class="popover_input">
													<el-input v-model="scope.row.copyname"></el-input>
												</div>
												<div class="popover_group">
													<span>保存</span>
													<span @click.stop="nameCancel(scope)">取消</span>
												</div>
											</div>
										</div>
										<div class="goodcompile" slot="reference">
											<i class="el-icon-edit"></i>
										</div>
									</el-popover>
								</div>
							</div>
						</template>
						<template v-else>
							<template v-if="Array.isArray(scope.row[value.prop])">
								<div class="recommend">
									<p v-for="(i,b) in scope.row[value.prop]" :key="b">
										{{i.key}}: <span>{{ i.value==1 ? "是" : "否" }}</span>
									</p>
								</div>
							</template>
							<template v-else>
								<p v-if="value.attributes" :style="value.attributes">{{ scope.row[value.prop] }}</p>
								<template v-else>{{ scope.row[value.prop] }}</template>
							</template>

						</template>
					</template>
					<!--扩展dom-->
					<template v-else>
						<Table :key="`cus${index}`" :render="value.render" :param="scope.row"></Table>
					</template>
				</template>
			</el-table-column>
			<!--基础操作-->
			<el-table-column label="操作" :min-width="operator.width" :fixed="operator.fixed">
				<template slot-scope="scope">
					<div class="caozuo">
						<template v-for="(value, index) in operator.list">
							<div @click.stop="operaTab(scope.row, value)" :key="index" v-if="value.show">
								{{ value.text }}
							</div>
						</template>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<!--分页插件-->
		<div class="orderlastbut">
			<div class="orderlastbut_left d-flex align-items-center">
				<template v-if="tableFootBtn.is_show">
					<el-checkbox v-model="all_checked" @change="allchange"></el-checkbox>
					<p class="page_num">共{{ total }}条</p>
					<template v-for="(item, index) in tableFootBtn.btn">
						<el-button class="grounding" :key="index" @click.stop="tableFootClick(item)">{{ item.name }}
						</el-button>
					</template>
					<el-cascader v-model="tableFootBtn.cascadervalue" class="foot_cascader"
						:options="tableFootBtn.cascoptions" @change="cascChange"></el-cascader>
				</template>
			</div>
			<div class="orderlastbutright">
				<el-pagination v-show="total > 0" :total="total" :page-size.sync="pageSize"
					:current-page.sync="currentPage" :page-sizes="[10, 20, 30, 50]"
					layout="total, sizes, prev, pager, next, jumper" @current-change="handleCurrentChange"
					@size-change="handleSizeChange" v-bind="$attrs">
				</el-pagination>
			</div>
		</div>
		
		<changePrice ref="changepice"></changePrice>
	</div>
</template>

<script>
	import axios from "axios";
	import Table from "./table";
	export default {
		name: "tableList",
		components: {
			Table,
		},
		props: {
			tableTitle: {
				type: Object,
				default: {
					stripe: false,
					border: false,
					fit: true, //	列的宽度是否自撑开
					highlightCurrentRow: false,
				},
			},
			firstTableCol: {
				type: Object,
				default: {
					select: false,
					width: "width",
					type: "selection",
				},
			},
			tableCol: {
				type: Array,
				default: [],
			},
			tableData: {
				type: Array,
				default: [],
			},
			operator: {
				type: Object,
				default: [],
			},
			total: {
				type: Number,
				default: 0,
			},
			page: {
				type: Number,
				default: 1,
			},
			limit: {
				type: Number,
				default: 10,
			},
			autoScroll: {
				type: Boolean,
				default: true,
			},
			tableFootBtn: {
				type: Object,
				default: {},
			},
			tableTopBtn: {
				type: Object,
				default: {},
			},
		},
		computed: {
			currentPage: {
				get() {
					return this.page;
				},
				set(val) {
					this.$emit("update:page", val);
				},
			},
			pageSize: {
				get() {
					return this.limit;
				},
				set(val) {
					this.$emit("update:limit", val);
				},
			},
		},
		data() {
			return {
				all_checked: false,
			};
		},
		created() {},
		methods: {
			// 监听table选择框
			handleSelectionChange(selection) {
				// 调用父组件对应的方法 handleSelectionChange
				this.$emit("handleSelectionChange", selection);
			},
			// 监听每页多少条数据（limit）
			handleSizeChange(limit) {
				this.$emit("pagination", {
					page: this.currentPage,
					limit: limit,
				});
				if (this.autoScroll) {
					scrollTo(0, 800);
				}
			},
			// 监听当前是第几页（page）
			handleCurrentChange(page) {
				this.$emit("pagination", {
					page: page,
					limit: this.pageSize,
				});
				if (this.autoScroll) {
					scrollTo(0, 800);
				}
			},
			operaTab(row, col) {
				let that = this;
				if (col.action == "action") {
					this.executeRequest(col.api, col.api_method)
				} else if (col.action == "redirect") {
					that.$router.push("" + col.url);
				}
			},
			executeRequest(url, methods) {
				let params = [];
				//   let list=this.ruleForm.list;
				//   for(let i in list){
				//     params.push({
				//       key:''+list[i].key,
				//       value:''+list[i].parameters.inputName,
				//       op:''+list[i].form
				//     })
				//   }
				console.log(url, methods)
				//   this.$axios[methods](url).then(res=>{
				//     console.log(res)
				//   })
			},
			// 选择所有商品
			allchange(value) {
				if (!value) {
					this.$refs.multipleTable.clearSelection();
				} else {
					this.$refs.multipleTable.toggleAllSelection();
				}
				this.all_checked = value;
			},
			// 底部的点击按钮
			tableFootClick(item) {
				if(item.action == "window_page"){
				console.log(item.action)
					this.$refs.changepice.modifytype = true;
				}
			},
			// 更多操作
			cascChange() {},
		},
	};
</script>
<style scoped>
	/* .orderlastbutright{float: none;margin: 0 auto;display: table;} */
	.orderlastbut {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}

	.page_num {
		margin-left: 12px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #7d7e80;
		line-height: 21px;
	}

	.grounding {
		height: 32px;
		/* line-height: 32px; */
		padding-top: 0;
		padding-bottom: 0;
		margin-left: 6px;
		padding: 12px 8px !important;
		line-height: 0;
	}

	.grounding:focus {
		background: #fff;
		border: 1px solid #dcdfe6;
		color: #606266;
	}

	.foot_cascader {
		margin-left: 10px;
		min-width: 112px;
		width: 112px;
		height: 32px;
	}

	.foot_cascader>>>.el-input {
		/* height: 32px; */
	}

	.foot_cascader>>>.el-input input {
		/* height: 32px; */
	}

	.foot_cascader>>>.el-input--suffix {
		/* line-height: 32px;
		height: 32px; */
		display: block;
	}

	.foot_cascader>>>.el-input--suffix .el-input__suffix .el-input__suffix-inner .el-input__icon {
		line-height: 32px;
	}

	.goods_detail_img {
		width: 50px;
		height: 50px;
		margin-right: 5px;
	}

	.goods_detail_img img {
		width: 100%;
		height: 100%;
	}

	.table_name {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		flex: 1;
	}

	.table_name span {
		color: #2f74f5;
		background: #e9f0ff;
		padding: 1px 4px;
		font-size: 14px;
		line-height: 14px;
		font-weight: 400;
		border-radius: 2px;
	}

	.recommend p span {
		color: rgb(153, 153, 153);
	}

	.caozuo div {
		padding: 0 10px;
		border-right: 1px solid var(--fontColor, #fb6638);
		cursor: pointer;
		display: inline-block;
		line-height: 1;
		color: var(--fontColor);
	}

	.caozuo div:last-child {
		border-right: 0;
	}
</style>
