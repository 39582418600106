<template>
	<el-card :ref="component_id" style="overflow: initial;">
		<!-- 需要挂载的外部弹窗组件 -->
		<div v-for="(item, index) in code_data.other_dialog" :key="index">
			<!-- 导入 -->
			<diy-import-more ref="diyImportMore" :dialogTitle="item.dialog_title" v-if="item.type == 'import'"
				:dialogImportUrl="item.dialog_import_url" :dialogTemplateUrl="item.dialog_template_url"
				@dialogImportCallback="importCallback"></diy-import-more>

			<!-- 导出 -->
			<diy-export-more ref="diyExportMore" :dialogTitle="item.dialog_title" v-if="item?.type == 'export'"
				:dialogExportKeys="item.dialog_export_keys" :dialogExportUrl="item.dialog_export_url"
				:dialogExportIsSearch="item.dialogExportIsSearch"></diy-export-more>

			<!-- 拉取商品 -->
			<shopselector ref="shopList" @change="goodshoplist($event, item)" v-if="item?.type == 'shopselector'"
				:pull_goods_url="item.goods_url" :pull_class_url="item.class_url" :pull_brand_url="item.brand_url"
				:pull_group_url="item.group_url">
			</shopselector>
			<!-- 邀请员工 -->
			<inviteUser ref="inviteUser" v-if="item?.type == 'invite'" @change="inviteSubmit"></inviteUser>
		</div>
		<!-- 需要挂载的自定义弹窗组件 -->
		<component v-for="(item, i) in code_data.dialog_dom" :is="item.type" :code_data="item" :$formData="childAndFather"
			:ref="item.ref"></component>
		<!-- 搜索 -->
		<div class="global-search-box">
			<div class="search-top-button" v-if="code_data.top_buttons.length">
				<template v-for="(item, i) in code_data.top_buttons">
					<component is="component.button" :code_data="item" :$formData="childAndFather" :code_this="code_this">
					</component>
				</template>

			</div>
			<div class="search-content">
				<component v-for="item in code_data.components.slice(0, code_data.component_length) " :key="item.key"
					:$formData="formData" :is="item.type" :code_data="item"></component>
				<template v-if="code_data.components.length > code_data.component_length && showAll">
					<component v-for="item in code_data.components.slice(code_data.component_length)" :key="item.key"
						:$formData="formData" :is="item.type" :code_data="item"></component>
				</template>
				<el-button v-if="code_data.components.length > code_data.component_length" type="text"
					@click="showAll = !showAll">
					高级筛选
					<i :class="!showAll ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i>
				</el-button>
			</div>
			<div class="serach-buttom-button">
				<!-- 底部固定按钮 搜索、重置 -->
				<el-button type="primary" @click="search_submit">搜索</el-button>
				<el-button @click="close_setting">重置</el-button>
				<component v-for="(item, index) in code_data.attach_buttons" is="component.button" :code_data="item"
					:$formData="childAndFather" :code_this="code_this">
				</component>
			</div>
		</div>
	</el-card>
</template>
<script>
export default {
	name: 'component.search',
	props: {
		code_data: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			component_id: this.code.domId(this.code_data),
			formData: {},
			keyMap: {},
			requestData: {},
			dataListObject: null,
			childAndFather: {},
			code_this: this,
			showAll: false,
			dataListObject: null,
		};
	},
	watch: {
		code_data: {
			handler(newVal, oldVal) {
				// console.log(newVal, oldVal, 'search  监听到了吗');
				this.init()
			},
			deep: true,
			immediate: true
		}
	},
	created() {

	},
	mounted() {

	},
	methods: {
		init() {
			let formRules = {};
			let formData = {};
			let keyMap = {};
			for (let i in this.code_data.components) {
				let component = this.code_data.components[i];
				//数据格式化
				component.id = this.code.domId(component);
				if (component.data_source && component.data_source.type != 'Api') {
					component.data_source.successCallback = function(domThis, res) {
						console.log(res, 'resres')
						component.data_source['source_data'] = res;
					}
					console.log(component.data_source, 'component.data_source');
					this.code.formatDataSource(this, component.data_source);
				}
				formRules[component.key] = component.rules;
				let _formdata = {
					op: component.op,
					value: this.code.formatDataValue(component, component?.default ?? ''),
					key: component.key,
					type: component.type,
					label: component.label
				}
				formData[component.key] = _formdata;
				keyMap[component.key] = component;
			}
			// 需要处理成对象，因为子组件需要通过key直接操控父组件
			let childAndFather = {}
			for (let i in this.code_data.dialog_dom) {
				let dialog_key = this.code_data.dialog_dom[i].dialog_key;
				if (dialog_key) {
					childAndFather[dialog_key] = false
				}
			}

			this.formRules = formRules
			this.formData = formData;
			this.childAndFather = childAndFather;

		},
		extractSearch() {
			let _data = {}
			//console.log(this.$parent?.$refs?.['component.table']?.length, 'asdasdasd');
			if (this.$parent?.$refs?.['component.table']?.length) {
				const { currentPage, eachPage, sort_type, sort_prop } = this.$parent.$refs['component.table'][0]
				_data = {
					search: [],
					order: [{
						key: sort_prop,
						value: sort_type
					}],
					page: currentPage,
					limit: eachPage
				};
			} else {
				_data = {
					search: [],
					order: [{
						key: 'id',
						value: 'desc'
					}],
					page: 1,
					limit: 10
				};
			}

			let requestData = Object.keys(this.formData).map(item => this.formData[item])

			let _ansRequest = [];
			for (let i = 0; i < requestData.length; i++) {
				let ansRequest = {}

				// 如果为数组，两项都有值再提取数据
				if (Array.isArray(requestData[i].value) && !requestData[i].value[0] && !requestData[i].value[1]) continue;
				// 如果为单项值，有值再提取数据
				if (requestData[i].value === null || requestData[i].value === '') continue;

				ansRequest['op'] = requestData[i].op
				ansRequest['key'] = requestData[i].key
				ansRequest['value'] = requestData[i].value
				if (requestData[i].type == 'component.search.dateRangeInput' && requestData[i].value != null && requestData[i].value != "") {
					ansRequest['value'] = [this.common.timeStampTurnTime(requestData[i].value[0]), this.common.timeStampTurnTime(requestData[i].value[1])]
				}
				else if (requestData[i].type == 'component.search.intervalInput' && requestData[i].value[0] && requestData[i].value[1]) {
					if (requestData[i].value[0] > requestData[i].value[1]) {
						this.$message.warning(`${requestData[i].label}区间输入有误！`)
						return false
					}
				}
				_ansRequest.push(ansRequest)
			}
			_data.search = _ansRequest
			return _data
		},
		// 获取table数据
		getTableData() {
			this.$parent.$refs['component.table'][0].getTableData()
		},
		search_submit() {
			this.$parent.$refs['component.table'][0]['currentPage'] = 1
			this.$parent.$refs['component.table'][0]['eachPage'] = 10
			this.getTableData()
		},
		close_setting() {
			Object.keys(this.formData).forEach(item => {
				if (this.formData[item].type == 'component.search.intervalInput') {
					this.formData[item].value = [null, null]
				} else {
					this.formData[item].value = ""
				}
			})
			this.$parent.$refs['component.table'][0]['currentPage'] = 1
			this.$parent.$refs['component.table'][0]['eachPage'] = 10
			this.getTableData()
		},
		// 导入dialog的回调
		importCallback() {
			this.getTableData()
		},
		// 选择商品的回调
		goodshoplist(value, item) {
			if (!value) {
				return false
			}
			let goods = value.map(item => item.id);

			let _data = {
				activity_id: this.$route.query['activity_id'],
				goods
			}
			//console.log(value);
			this.$post(item.url, _data).then(res => {
				if (res.code == 200 && res.data.result) {
					this.getTableData()
				} else {
					this.$message.warning(res.message)
				}
			}).catch(error => {
				this.$message.error(error.message)
			})
		},
		inviteSubmit() {
			this.getTableData()
		},
	}
}
</script>
<style lang="scss" scoped>
.nav_main_header {
	padding-bottom: 20px;
	overflow: hidden;
	margin-bottom: 20px;
	box-shadow: inset 0 -1px 0 #efefef;
}

.purchaseup {
	margin-top: 20px;
	width: 100%;
	border-radius: 4px;
	margin-bottom: 20px;
	background: #fff;
	padding: 20px;
	box-sizing: border-box;

	button {
		float: left;
		height: 38px;
		padding: 0 16px;
		font-size: 14px;
		margin-right: 8px;
	}
}

.grouptable {
	width: 100%;
	padding: 20px;
	border-radius: 4px;
	background: #fff;
	box-sizing: border-box;
}

.table_foot {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.el-table th.el-table__cell {
	background-color: #f9f9fc;
	font-weight: 600;
	color: #333;
	padding: 8px 0;
}

.el-table td.el-table__cell {
	color: #333;
}

.search-bottom {
	display: flex;
	justify-content: space-between;
}

.attach_buttons {
	display: flex;
}
</style>