<!-- 
	code 平台的下的 表单组件
        item.parameters.tips 是input的默认提示语，class使用 default-tips
        item.parameters.toast 是input的标红提示语，class使用 warning-toast
 -->
<template>
	<div class="class_container">
		<el-card>
			<div class="class_head" v-if="code_data.parameters?.title">
				<p>{{ code_data.parameters.title }}</p>
				<p class="sub-title" v-if="code_data.parameters?.sub_title">{{ code_data.parameters.sub_title }}</p>
			</div>
			<div class="class_addnav">
				<el-form :label-width="code_data.parameters.label_width ?? '100px'" :rules="formRules" :model="formData"
					:ref="component_id">
					<el-form-item v-for="(item, i) in  code_data.components " :label="item?.label" :prop="item?.key">
						<!-- 
							component.form.collection  类似采集事件
							定义：左输入框，右点击事件
							事件：走api修改 formData 的值
							预留方法 接收 子组件传过来的值
							-->
						<component :is="item.type" :code_data="item" :$formData="formData"
							v-if="item.type == 'component.form.collection'" @changeChildCallback="childCallback">
						</component>
						<component :is="item.type" :code_data="item" :$formData="formData" v-else>
						</component>
						<div v-if="item.parameters?.tips" class="default-tips">{{ item.parameters.tips }}</div>
						<div v-if="item.parameters?.toast" class="warning-toast">{{ item.parameters.toast }}</div>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<diy-footer-button>
			<el-button v-if="code_data.is_back" @click="go_back">取消</el-button>
			<el-button type="primary" @click="submit()" :loading="submitLoading">保存</el-button>
		</diy-footer-button>
	</div>
</template>
<script>
export default {
	name: 'component.form',

	props: {//传递参数
		// v-model
		code_data: {
			type: Object,
			required: true
		},
	},
	data() {

		return {
			component_id: this.code.domId(this.code_data),
			formRules: {},
			formData: {},
			keyMap: {},
			requestData: {},
			is_multiple: false,
			uploadImge: {},
			submitLoading: false
		};
	},
	watch: {
		code_data: {
			handler(newVal, oldVal) {
				const value = Object.is(newVal, oldVal);
				if (!value) {
					this.init()
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {

	},
	methods: {
		init() {
			// 组件初始化
			let formRules = {};
			let formData = {};
			let keyMap = {};
			for (let i in this.code_data.components) {
				let component = this.code_data.components[i];
				//数据格式化
				component.id = this.code.domId(component);
				if (component.data_source && component.data_source.type != 'Api') {
					component.data_source.successCallback = function(domThis, res) {
						component.data_source['source_data'] = res;
					}
					this.code.formatDataSource(this, component.data_source);
				}

				formRules[component.key] = component.rules;
				formData[component.key] = this.code.formatDataValue(component, component?.default ?? '', this);
				keyMap[component.key] = component;
				// 很多情况下，组件会有很多的值，但是也都需要提交，所以把值取出来，并到父级大数据里面
				if (component?.sub_key) {
					component.sub_key.forEach(item => {
						formData[item] = component[item]
						keyMap[item] = component
					})
				}
				// 很多情况下，组件会有很多的子集，但是也都需要提交，所以把值取出来，并到父级大数据里面
				if (component?.diy_input) {
					component.diy_input.children.forEach(item => {
						formData[item.key] = this.code.formatDataValue(item, item?.default ?? '');
						keyMap[item.key] = item;
					})
				}
			}

			this.formRules = formRules;
			this.formData = formData;
			this.keyMap = keyMap;

			if (this.code_data.data_source) {
				this.code_data.data_source['successCallback'] = function(domThis, res) {
					// console.log(res, 'form source');
					if (domThis.code_data.data_source?.is_filter == '.->#') {
						let handleFrom = {}
						Object.keys(res).forEach(item => {
							handleFrom[item.split('.').join('#')] = res[item]
						})
						domThis.formData = { ...domThis.formData, ...handleFrom }
					} else {
						domThis.formData = res
					}
				}
				this.code_data.data_source['failCallback'] = function(domThis, err) {
					//console.log('fail load data', err);
				}
				this.code.formatDataSource(this, this.code_data.data_source);
			}

		},
		// 取消
		go_back() {
			this.$router.go(-1)
		},
		// 提交
		submit() {
			this.requestData = this.code.formatRequestData(this, this.data, this.keyMap, this.formData);
			//console.log("requestData", this.requestData);
			this.$refs[this.component_id].validate((valid) => {
				if (valid) {
					let responseFrom = {};
					// 是否需要加工数据
					if (this.code_data.api?.is_filter == '#->.') {
						Object.keys(this.requestData).forEach(item => {
							responseFrom[item.split('#').join('.')] = this.requestData[item]
						})
					} else {
						responseFrom = this.requestData
					}
					this.submitLoading = true
					this.code.request(this, this.code_data.api, responseFrom, function(domThis, res) {
						if (res.code == 200) {
							domThis.$message.success(res.message);
							if (domThis.code_data.is_back) {
								domThis.$router.go(-1)
							} else {
								domThis.code.formatDataSource(domThis, domThis.code_data.data_source);
							}
						} else {
							domThis.$message.error(res.message);
						}
						domThis.submitLoading = false
					}, (domThis, error) => {
						domThis.$message.error(error.message);
						domThis.submitLoading = false
					});
				} else {
					return false;
				}
			})
		},
		childCallback(val) {
			//console.log(val, 'valvalval');
		}
	},
	mounted() {
		//console.log(this.component_id);
	},
};
</script>
<style scoped lang="scss">
::v-deep .el-card {
	overflow: initial !important;
}

nav {
	background: #fff;
	display: flex;
	flex: 1;
	margin-top: 20px;
	padding-bottom: 60px;
}

.class_container {
	margin-top: 20px;
}

.class_head {
	border-bottom: 1px solid #f2f2f2;
	padding: 8px 20px;
	line-height: 1;
}

.class_head p {
	display: inline-block;
	width: auto;
	line-height: 28px;
	font-size: 16px;
	color: #333;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 700;

	&.sub-title {
		font-size: 14px;
		color: #999;
		margin-left: 10px;
	}
}

.class_addnav {
	margin: 20px;
}

.class_foot {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.class_btn {
	background: var(--fontColor);
	color: #fff;
	padding: 0 16px;
	height: 38px;
	font-size: 14px;
	border-radius: 4px;
	line-height: 38px;
	cursor: pointer;
	display: inline-block;
	border: none;
	border-color: transparent;
}

.attr_foot_cancel {
	border: 1px solid transparent;
	color: #666;
	background-color: #fff;
	border-color: #ededed;
	margin-right: 20px;
}

footer {
	position: fixed;
	left: 0;
	padding-left: 160px;
	box-sizing: border-box;
	right: 0;
	bottom: 0;
	width: 100%;
	margin: 0 24px;
	height: auto;
	border-top: 1px solid #f1f1f1;
	z-index: 6;
	background-color: #fff
}

.class_input .el-input {
	width: 400px;
}

.tradeTips {
	color: #636c72;
	font-size: 14px;
}

.class_input .el-textarea {
	width: 400px;
	height: 150px;
}

.class_input .el-textarea .el-textarea__inner {
	height: 150px;
	resize: none;
}

.class_input .el-textarea .el-input__count {
	line-height: 1;
}

.default-tips {
	color: #636c72;
	font-size: 14px;
}

.warning-toast {
	color: #d9534f;
	font-size: 14px;
}
</style>