<template>
  <div class="inline-block goods_selected clearfloat">
    <div class="orderfirstleft" v-if="param.is_label" style="width: 110px">
      <el-select v-model="param.title" :placeholder="param.labelList[0].label">
        <el-option
          v-for="(item, index) in param.labelList"
          :key="index"
          :value="item.value"
          :label="item.label ? item.label : item.value"
          :disabled="item.disabled"
        ></el-option>
      </el-select>
    </div>
    <span v-else> {{ param.title }}： </span>
    <div class="search_headline inline-block">
      <template v-if="param.form == 'text'">
        <template v-if="!param.parameters.is_multi">
          <el-input
            placeholder="请输入商品名称"
            v-model="param.parameters.inputName"
          >
          </el-input>
        </template>
        <template v-else>
          <template v-for="(item, index) in param.parameters.leastList">
            <div class="goods_sales inline-block">
              <el-input
                type="number"
                min="0"
                v-model="param.parameters.leastList[index]"
                placeholder=""
              >
              </el-input>
            </div>
            <span v-if="param.parameters.leastList.length - 1 > index">-</span>
          </template>
        </template>
      </template>
      <template v-else-if="param.form == 'select'">
        <template v-if="param.parameters.multiSelect">
          <div class="orderselect orderselectlist clearfloat">
            <template>
              <el-select
                v-for="(item, index) in param.parameters.fullyActive"
                :key="index"
                v-model="param.parameters.fullyActive[index]"
                placeholder="请选择"
                @change="provinceChange($event, index)"
              >
                <template v-if="index == 0">
                  <el-option
                    v-for="(i, b) in param.parameters.fullyGood"
                    :key="b"
                    :label="i.provinceName ? i.provinceName : i.provinceCode"
                    :value="i.provinceCode"
                  ></el-option>
                </template>
                <template v-if="index == 1">
                  <el-option
                    v-for="(i, b) in param.parameters.fullyGood[param.parameters.CityArry[0]].cities"
                    :key="b"
                    :label="i.cityName ? i.cityName : i.cityCode"
                    :value="i.cityCode"
                  ></el-option>
                </template>
                <template v-if="index == 2">
                  <el-option
                    v-for="(i, b) in param.parameters.fullyGood[param.parameters.CityArry[0]].cities[param.parameters.CityArry[1]].counties"
                    :key="b"
                    :label="i.countyName ? i.countyName : i.countyCode"
                    :value="i.countyCode"
                  ></el-option>
                </template>
              </el-select>
            </template>
          </div>
        </template>
        <template v-else>
          <el-select
            v-model="param.parameters.inputName"
            filterable
            :placeholder="param.attributes.placeholder"
			:multiple ="param.parameters.multiple"
          >
            <el-option
              v-for="(i, b) in param.parameters.selectOptions"
              :key="b"
              :label="i.label ? i.label : i.value"
              :value="i.value"
            >
            </el-option>
          </el-select>
        </template>
      </template>
	  <template v-else-if="param.form == 'cascader'">
		  <el-cascader placeholder="请选择商品分类" filterable
			  v-model="propvalue"
			  :options="param.parameters.Cascaderoptions"
			  @change="handleChange"></el-cascader>
	  </template>
      <template v-else-if="param.form == 'date'">
        <el-date-picker
          v-model="param.parameters.inputName"
          type="datetimerange"
          start-placeholder="开始日期"
          format="yyyy-MM-dd"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <template v-if="param.parameters.is_recently">
          <div class="rankrighted clearfloat">
            <button class="active">最近7天</button>
            <button>最近15天</button>
            <button>最近30天</button>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name:"searchInput",
  props: {
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      param: this.params,
	  propvalue: '',
    };
  },
  created() {},
  methods: {
	handleChange(value) {
		console.log(value);
	},
    provinceChange(value, index) {
      let CityArry=this.param.parameters.CityArry||[];
      if (index == 0) {
        // 根据选中省，匹配市
        let provinceList = this.param.parameters.fullyGood;
        provinceList.forEach((item, index) => {
          if (item.provinceCode == value) {
            CityArry[0] = index;
            this.param.parameters.fullyActive[1]='';
          }
        });
      }else if(index==1){
        let provinceList = this.param.parameters.fullyGood[CityArry[0]].cities;
        provinceList.forEach((item, index) => {
          if (item.cityCode == value) {
            CityArry[1] = index;
            this.param.parameters.fullyActive[2]='';
          }
        });
      }else{
        let provinceList = this.param.parameters.fullyGood[CityArry[0]].cities[CityArry[1]].counties;
        provinceList.forEach((item, index) => {
          if (item.countyCode == value) {
            CityArry[2] = index;
          }
        });
      }
      this.param.parameters.CityArry=CityArry;
    },
  },
};
</script>
<style scoped>
.orderfirstleft {
  float: left;
}
.inline-block > span {
  padding: 0 10px;
  width: 90px;
  display: inline-block;
  text-align: right;
  font-size: 14px;
}
.goods_selected .search_headline .el-input__inner {
    /* height: 38px; */
	border-radius: 4px;
}
.goods_selected .search_headline >>> .el-input__inner{
  /* height: 38px; */
  border-radius: 4px !important;
}
.goods_selected .search_headline >>> .el-input__inner:focus {
  border: 1px solid #000c9a;
  border-radius:4px;
}
.search_headline >>> .el-select {
  width: 270px;
}
.search_headline >>> .el-cascader {
  width: 270px;
}

.search_headline >>> .el-select.is-focus {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.search_headline >>> .el-select:focus {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}


.search_headline >>> .el-select:hover .el-input__inner {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.goods_sales {
  width: 117px;
  height: 38px;
}

.goods_sales >>> .el-input__inner {
  /* height: 38px; */
}

.search_headline > span {
  padding: 0 10px;
  width: auto;
  display: inline-block;
  text-align: right;
  font-size: 14px;
}
.orderselect {
  padding-top: 14px;
}
.orderselect >>> .el-input__inner {
  background: #fff !important;
  border-radius: 4px !important;
  /* height: 38px; */
  /* line-height: 38px !important; */
}
.orderselectlist >>> .el-select {
  float: left;
  width: 32%;
  margin-right: 2%;
}
.orderselectlist >>> .el-select:last-child {
  margin-right: 0;
}
.orderselectlist >>> .el-input__inner {
  padding-left: 8px;
}
</style>