export default {
  install(Vue, options) {
    // 1.读取lib文件夹下的文件
    // const req = requeire.context('路径', 是否读取子文件夹, /正则匹配/)
    const req = require.context('../', true, /\.vue$/)
    // req是一个function函数（传入读取文件路径后可导入文件）
   
    req.keys().forEach((item) => {
      // console.log(req(item).default)
      // req(item).default==导入了该文件路径
      const com = req(item).default
      // 全局注册
      Vue.component(com.name, com)
    })
    // console.log(options)
  }
}