<template>
    <div class="component-form-input">
        <!-- 多图上传 -->
        <div class="d-flex align-items-center" v-if="is_multiple == 1">
            <div class="sklist">
                <div class="skeletonize" v-for="(ii, index) in $formData[code_data.key]" :key="index"
                    :style="{ width: (code_data?.image_width ? code_data.image_width : 50) + 'px', height: (code_data?.image_height ? code_data.image_height : 50) + 'px' }">
                    <img :src="ii" alt="" />
                    <span @click="imageDelect(index)">删除</span>
                </div>
                <div class="add-select" @click="imagePhotoVisible(code_data)"
                    :style="{ width: (code_data?.image_width ? code_data.image_width : 50) + 'px', height: (code_data?.image_height ? code_data.image_height : 50) + 'px' }">
                    <i class="el-icon-plus"></i>
                </div>
            </div>
        </div>
        <!-- 单图上传 -->
        <div class="d-flex align-items-center" v-else>
            <div class="add-select" @click="imagePhotoVisible(code_data)"
                :style="{ width: (code_data?.image_width ? code_data.image_width : 50) + 'px', height: (code_data?.image_height ? code_data.image_height : 50) + 'px' }">
                <img v-if="$formData[code_data.key]" :src="$formData[code_data.key]" alt="" :onerror="errorImg">
                <i class="el-icon-plus" v-else></i>
            </div>
        </div>
        <!-- 图片选择器 -->
        <pictureSelect ref="imglist" :multipled="is_multiple" @change="imageBrand($event)"></pictureSelect>
    </div>
</template>

<script>
export default {
    name: 'component.form.image',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {
            errorImg: 'this.src="' + require("../../../assets/images/lodingsb.svg") + '"', //图片加载失败
            is_multiple: this.code_data.parameters?.is_multiple ? 1 : 0,
            uploadImge: {},
        }
    },
    methods: {
        // 图片选择器
        imagePhotoVisible(obj) {
            let type = 1;
            this.uploadImge = {
                obj: obj,
                type: type
            };
            this.$refs.imglist.is_multiple = this.is_multiple;
            this.$refs.imglist.photoVisible = true;
        },
        imageBrand(val) {
            //console.log(val, 'valvalval');
            // return false;
            let { key } = this.uploadImge.obj;
            console.log(key, 'key', this.$formData);
            if (this.is_multiple) {
                let _array = [];
                if (Array.isArray(this.$formData[key])) {
                    _array = [...this.$formData[key]]
                }
                for (var i in val) {
                    _array.push(val[i].file_url);
                }
                this.$formData[key] = _array

            } else {
                console.log(this.$formData[key], 'sadf');

                this.$formData[key] = val[0].file_url;
            }

        },
        imageDelect(index) {
            let { key } = this.uploadImge.obj;
            this.$formData[key].splice(index, 1);
        },
    }
}
</script>

<style lang="scss" scoped>
.sklist {
    display: flex;
}

.skeletonize {
    margin-right: 10px;
    border: 1px dashed #ededed;
    text-align: center;
    color: #dad9d9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;

    img {
        display: block;
        width: auto;
        height: 100%;
        object-fit: cover;
    }
}

.add-select {
    margin-right: 10px;
    border: 1px dashed #ededed;
    color: #dad9d9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        display: block;
        object-fit: cover;
    }
}
</style>