/*
 * @Author: yyx yyx0122@163.com
 * @Date: 2022-06-20 09:32:36
 * @LastEditors: yyx yyx0122@163.com
 * @LastEditTime: 2022-06-20 10:19:18
 * @FilePath: \dist\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';
import qs from 'qs';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import vue from '../main'
// let Base64 = require('js-base64').Base64
// let token = Base64.decode(basejm);

let loading;

function startLoading() {
	// console.log(router.app._route)
	let loadingTarget = document.querySelector('.loading-area');
	let customClass = '';
	if (router.app._route.path != '/signin') {
		loadingTarget = document.querySelector('.boxrightconter');
		customClass = 'customClass'
	}
	loading = Vue.prototype.$loading({
		lock: true,
		text: "",
		target: loadingTarget, //设置加载动画区域
		customClass: customClass,
	});
}

function endLoading() {
	loading.close();
}

//声明一个对象用于存储请求个数
let needLoadingRequestCount = 0;

function showFullScreenLoading() {
	if (needLoadingRequestCount === 0) {
		startLoading();
	}
	needLoadingRequestCount++;
};0

function tryHideFullScreenLoading() {
	if (needLoadingRequestCount <= 0) return;
	needLoadingRequestCount--;
	if (needLoadingRequestCount === 0) {
		endLoading();
	}
};

let baseURL = '/admin'
var axioss = axios.create({
	baseURL,
	timeout: 100000,
	headers: {
		'Content-Type': 'application/json; charset=UTF-8',
	}
	
})

// axios.defaults.baseURL = '/admin';
// axios.defaults.headers['Content-Type'] = 'application/json;charSet=UTF-8';

// 添加请求拦截器
axioss.interceptors.request.use((config) => {
	showFullScreenLoading();
	// axios中判断如果data是undefined，则删除content-type属性
	config.data || (config.data = {});
	// 携带token 身份验证
	let Token = store.state.user
	// console.log(Token)
	if (Token && Token.access_token) {
		config.headers.Authorization = `Bearer ${Token.access_token}`
	}
	return config
}, (error) => {
	tryHideFullScreenLoading();
	return Promise.reject(error)
})

// 添加响应拦截器
axioss.interceptors.response.use(function(response) {
	tryHideFullScreenLoading();
	const responseData = response.data;
	// console.log(responseData)
	// console.log(router);
	if (responseData.code == 0 || responseData.code == 200) {
		//返回自定义信息
		return Promise.resolve(responseData);
	} else if (responseData.code == -1 || responseData.code == 1100 || responseData.code == 1101) {
		
		// vue.common.signout(vue);
		window.localStorage.removeItem("userInfo");
		if(vue.$route.path!='/signin'){
			router.replace({
				name: 'signin',
				query: {
					redirect: router.currentRoute.fullPath
				}
			})
		}
		return Promise.reject(responseData);
	}else if(responseData.code == 1102){
		// vue.common.signout(vue);
		if(vue.$route.path!='/signin'){
			router.replace({
				name: 'signin',
				query: {
					redirect: router.currentRoute.fullPath
				}
			})
		}
		return Promise.reject(responseData);
		// const userInfo = vue.$store.state.user
		// if (userInfo&&userInfo.access_token != "") {
		// 	vue.$post(vue.$apis.refresh).then(res => {
		// 		console.log(res)
		// 		if (res.code == 200) {
		// 			userInfo.access_token = res.data.refresh_token;
		// 			window.localStorage.setItem("userInfo",userInfo);
		// 		} 
		// 	}).catch(err => {
		// 		vue.common.message(vue, err.message);
		// 	});
		// }
	} else {
		tryHideFullScreenLoading();
		// 安装
		if(Object.keys(responseData.data).length != 0){
			window.location.href = responseData.data.redirect
		}
		return Promise.resolve(responseData);
	}
}, function (error) {
	//提示
	// message.error('服务器无响应')
	//响应
	tryHideFullScreenLoading();
	return Promise.reject(error);
});



// const aa = function (vue){
// 	// let Token = vue.store.state.user.access_token;
// 	console.log(vue.$store.state.user);
// 	const userInfo = vue.$store.state.user
// 	if (userInfo&&userInfo.access_token != "") {
// 		vue.$post(vue.$apis.refresh).then(res => {
// 			console.log(res)
// 			if (res.code == 200) {
// 				userInfo.access_token = res.data.refresh_token;
// 				window.localStorage.setItem("userInfo",userInfo);
// 			} 
// 		}).catch(err => {
// 			vue.common.message(vue, err.message);
// 		});
// 	}
// };
export function get(url, params) {
	for (var i in params) {
		if (typeof params[i] == 'object') {
			params[i] = JSON.stringify(params[i]);
		}
	}
	return new Promise((resolve, reject) => {
		axioss.get(url, {
			params: params
		}).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}

export function post(url, params, header = '') {
	for (var i in params) {
		if (typeof params[i] == 'object') {
			params[i] = JSON.stringify(params[i]);
		}
	}
	return new Promise((resolve, reject) => {
		axioss.post(url, params, header).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}

export function put(url, params) {
	for (var i in params) {
		if (typeof params[i] == 'object') {
			params[i] = JSON.stringify(params[i]);
		}
	}
	return new Promise((resolve, reject) => {
		axioss.put(url, params).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}

export function del(url, params) {
	for (var i in params) {
		if (typeof params[i] == 'object') {
			params[i] = JSON.stringify(params[i]);
		}
	}
	return new Promise((resolve, reject) => {
		axioss.delete(url, {
			params: params
		}).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}

export function patch(url,params) {
	for (var i in params) {
		if (typeof params[i] == 'object') {
			params[i] = JSON.stringify(params[i]);
		}
	}
	return new Promise((resolve, reject) => {
		axioss.patch(url, params).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}
