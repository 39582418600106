<!-- 
	code 平台的下的 多表单组件
        item.parameters.tips 是input的默认提示语，class使用 default-tips
        item.parameters.toast 是input的标红提示语，class使用 warning-toast
 -->
<template>
	<div class="class_container">
		<el-card v-for="(item, index) in  code_data.children" :key="index">
			<div>
				<div class="class_head" v-if="item.parameters?.title">
					<p>{{ item.parameters.title }}</p>
					<p class="sub-title" v-if="item.parameters?.sub_title">{{ item.parameters.sub_title }}</p>
				</div>
				<div class="class_addnav">
					<el-form label-width="160px" :rules="formRules" :model="formData" :ref="component_id">
						<el-form-item v-for="(itm, idx) in  item.components " :label="itm.label" :prop="itm?.key">
							<component :is="itm.type" :code_data="itm" :$formData="formData"
								v-if="itm.type == 'component.form.collection'" @changeChildCallback="childCallback">
							</component>
							<component :is="itm.type" :code_data="itm" :$formData="formData"
								v-else-if="itm.type == 'component.dialog'">
							</component>
							<component :is="itm.type" :code_data="itm" :$formData="formData" v-else>
							</component>
							<div v-if="itm.parameters.tips" class="default-tips">{{ itm.parameters.tips }}</div>
							<div v-if="itm.parameters.toast" class="warning-toast">{{ itm.parameters.toast }}</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
		<diy-footer-button>
			<el-button @click="go_back">取消</el-button>
			<el-button type="primary" @click="submit()" :loading="submitLoading">保存</el-button>
		</diy-footer-button>
	</div>
</template>
<script>
export default {
	name: 'component.more.form',

	props: {//传递参数
		// v-model
		code_data: {
			type: Object,
			required: true
		},
	},
	data() {

		return {
			component_id: this.code.domId(this.code_data),
			formRules: {},
			formData: {},
			keyMap: {},
			requestData: {},
			is_multiple: false,
			uploadImge: {},
			submitLoading: false
		};
	},
	watch: {
		code_data: {
			handler(newVal, oldVal) {
				const value = Object.is(newVal, oldVal);
				if (!value) {
					this.init()
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {

	},
	methods: {
		init() {
			let formRules = {};
			let formData = {};
			let keyMap = {};
			const dataArray = [...this.code_data.children];
			dataArray.forEach(item => {
				for (let i in item.components) {
					let component = item.components[i];
					//数据格式化
					component.id = this.code.domId(component);
					if (component.data_source && component?.key) {
						component.data_source.successCallback = function(domThis, res) {
							component.data_source = res;
						}
						this.code.formatDataSource(this, component.data_source);
					}

					formRules[component.key] = component.rules;
					formData[component.key] = this.code.formatDataValue(component, component?.default ?? '');
					keyMap[component.key] = component;
					// 很多情况下，组件会有很多的值，但是也都需要提交，所以把值取出来，并到父级大数据里面
					if (component?.sub_key) {
						component.sub_key.forEach(item => {
							formData[item] = component[item]
							keyMap[item] = component
						})
					}
					// 很多情况下，组件会有很多的子集，但是也都需要提交，所以把值取出来，并到父级大数据里面
					if (component?.diy_input) {
						component.diy_input.children.forEach(item => {
							formData[item.key] = this.code.formatDataValue(item, item?.default ?? '');
							keyMap[item.key] = item;
						})
					}
				}
			})

			this.formRules = formRules;
			this.formData = formData;
			this.keyMap = keyMap;

			if (this.code_data.data_source) {
				this.code_data.data_source['successCallback'] = function(domThis, res) {
					domThis.formData = res;
				}
				this.code_data.data_source['failCallback'] = function(domThis, err) {
					//console.log('fail load data', err);
				}
			}
			this.code.formatDataSource(this, this.code_data.data_source);

		},
		// 取消
		go_back() {
			this.$router.go(-1)
		},
		// 提交
		submit() {
			this.requestData = this.code.formatRequestData(this, this.data, this.keyMap, this.formData);
			//console.log(this.requestData, 'this.requestData');
			const validateArray = [];
			this.$refs[this.component_id].forEach(item => {
				item.validate(val => {
					validateArray.push(val)
				})
			})
			const validate = validateArray.every(item => item)
			if (!validate) return false
			// 因为是多个表单，需要对表单进行轮询每个都进行数据校验通过才能继续往下走
			if (validate) {
				// 通常会有一些比较深层级的表单，校验层次穿不下去，所以需要手动校验
				if (this.code_data.is_validate) {
					const { is_validate } = this.code_data
					// 当需要特殊校验的绑定值一致的时候进行校验
					for (let index = 0; index < is_validate.length; index++) {
						const { key, is_bind, value, label } = is_validate[index]
						if (!this.isNotEmpty(this.formData[key]) && this.formData[is_bind] === value) {
							this.$message.warning(`${label}不能为空`)
							return false;
						}
					}
				}
			}

			// 校验完成
			let responseFrom = {};
			// 是否需要加工数据
			if (this.code_data.api?.is_filter == '_->.') {
				Object.keys(this.requestData).forEach(item => {
					responseFrom[item.split('_').join('.')] = this.requestData[item]
				})
			} else {
				responseFrom = this.requestData
			}
			this.submitLoading = true

			console.log(responseFrom, 'responseFrom');
			this.code.request(this, this.code_data.api, responseFrom, function(domThis, res) {
				if (res.code == 200) {
					domThis.$message.success(res.message);
					domThis.$router.go(-1)
				}
				domThis.submitLoading = false
			}, (domThis, error) => {
				domThis.$message.error(error.message);
				domThis.submitLoading = false
			});


		},
		childCallback(val) {
			//console.log(val, 'valvalval');
		},
		isNotEmpty(value) {
			if (Array.isArray(value)) {
				return value.length !== 0;
			} else if (typeof value === 'object' && value !== null) {
				return Object.keys(value).length !== 0;
			} else if (typeof value === 'string') {
				return value.trim() !== '';
			}

			return false;
		}
	},
	mounted() {
		//console.log(this.component_id);
	},
};
</script>
<style scoped lang="scss">
nav {
	background: #fff;
	display: flex;
	flex: 1;
	margin-top: 20px;
	padding-bottom: 60px;
}

.class_container {
	margin-top: 20px;
}

.class_head {
	border-bottom: 1px solid #f2f2f2;
	padding: 8px 20px;
	line-height: 1;
}

.class_head p {
	display: inline-block;
	width: auto;
	line-height: 28px;
	font-size: 16px;
	color: #333;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 700;

	&.sub-title {
		font-size: 14px;
		color: #999;
		margin-left: 10px;
	}
}

.class_addnav {
	margin: 20px;
}

.class_foot {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
}

.class_btn {
	background: var(--fontColor);
	color: #fff;
	padding: 0 16px;
	height: 38px;
	font-size: 14px;
	border-radius: 4px;
	line-height: 38px;
	cursor: pointer;
	display: inline-block;
	border: none;
	border-color: transparent;
}

.attr_foot_cancel {
	border: 1px solid transparent;
	color: #666;
	background-color: #fff;
	border-color: #ededed;
	margin-right: 20px;
}

footer {
	position: fixed;
	left: 0;
	padding-left: 160px;
	box-sizing: border-box;
	right: 0;
	bottom: 0;
	width: 100%;
	margin: 0 24px;
	height: auto;
	border-top: 1px solid #f1f1f1;
	z-index: 6;
	background-color: #fff
}

.class_input .el-input {
	width: 400px;
}

.tradeTips {
	color: #636c72;
	font-size: 14px;
}

.class_input .el-textarea {
	width: 400px;
	height: 150px;
}

.class_input .el-textarea .el-textarea__inner {
	height: 150px;
	resize: none;
}

.class_input .el-textarea .el-input__count {
	line-height: 1;
}

.default-tips {
	color: #636c72;
	font-size: 14px;
}

.warning-toast {
	color: #d9534f;
	font-size: 14px;
}
</style>