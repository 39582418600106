<template>
    <el-dialog title="转移分类" class="grounptc" :visible.sync="transfer_class">
        <div class="grounptcbox">
            <el-cascader placeholder="请选择或搜索分类" v-model="class_active" :options="cat_list" filterable clearable
                :props="{ value: 'id', label: 'label', children: 'children' }">
            </el-cascader>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="clearcatbox()">取 消</el-button>
            <el-button type="primary" @click="sealcat()">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "component.table.transfer_class",
    props: ['propsList', 'allCat', 'setCat'],
    data() {
        return {
            transfer_class: false,
            cat_list: [],
            class_active: []
        }
    },
    created() {
        // 获取分类数据
        this.getClass()
    },
    methods: {
        getClass() {
            if (!this.allCat) return false
            this.$get(this.code.formatTemplate(this, this.allCat))
                .then((res) => {
                    if (res.code == 200) {
                        this.cat_list = this.getTreeData(res.data.list);
                    } else {
                        this.common.message(this, res.message);
                    }
                })
                .catch((err) => {
                    this.common.message(this, err.message);
                });
        },
        getTreeData(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    data[i].children = undefined;
                } else {
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        clearcatbox() {
            this.class_active = ""
            this.transfer_class = false
        },
        sealcat() {
            if (!this.class_active.length) {
                this.$message.warning("请选择分类")
                return false;
            }
            let _data = {
                id: this.propsList.map(item => item.id),
                cat_id: this.class_active[this.class_active.length - 1],
            };
            this.$put(this.code.formatTemplate(this, this.setCat), _data)
                .then((res) => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.$emit('callback', true)
                        this.transfer_class = false;
                        this.class_active = [];
                    } else {
                        this.$message.warning(res.message)
                    }
                })
                .catch((err) => {
                    this.$message.error(res.message)
                });
        }
    }

}
</script>

<style lang="scss" scoped>
.grounptc /deep/ .el-dialog {
    width: 660px;
    border-radius: 2px;
}

.grounptcbox {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
</style>
