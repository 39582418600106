import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import "swiper/swiper.min.css"
import 'swiper/swiper-bundle.min.css'
import * as echarts from 'echarts'
import './assets/css/base.css'
import SlideVerify from 'vue-monoplasty-slide-verify';
import { nanoid } from 'nanoid'

// elementui的样式处理
import "./assets/css/element-variables.scss"

// import mock from "../mock";
import {
	get,
	post,
	put,
	del,
	patch
} from './api/http'
import api from "./api/api";
import common from "../common/common.js";
Vue.prototype.common = common;

import code from "../common/code.js";
Vue.prototype.code = code;

// 注册全局组件
import components from './components/lib'

Vue.use(components)

Vue.use(ElementUI);


Vue.use(SlideVerify);

String.prototype._trim = function(char, type) {
	if (char) {
		if (type == 'left') {
			return this.replace(new RegExp('^\\' + char + '+', 'g'), '');
		} else if (type == 'right') {
			return this.replace(new RegExp('\\' + char + '+$', 'g'), '');
		}
		return this.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');
	}
	return this.replace(/^\s+|\s+$/g, '');
};

Vue.prototype.$echarts = echarts;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$delete = del;
Vue.prototype.$patch = patch;
Vue.prototype.$apis = api;
Vue.prototype.$nanoid = nanoid;
Vue.config.productionTip = false;

import { useQiankun } from './utils/qiankun'

import { hasPermission } from "./utils/permission"
Vue.prototype.hasPerm = hasPermission

const app = new Vue({
	router,
	store,
	render: h => h(App)
})
app.$mount('#app');

app.$nextTick(() => {
	useQiankun()
})

export default app;
