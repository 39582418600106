import {
    registerMicroApps,
    start
} from 'qiankun';
import api from "../api/api";
import {
    get
} from '../api/http'
import { props } from "./initGlStore"
var jsonapp = "";
function activeRoute(route) {
    let url = 'http://localhost:';
    if (route == '/pc') {
        url += '8017'
    } else if (route == '/zycg') {
        url += '8015'
    } else if (route == '/collection') {
        url += '8016'
    } else if (route == '/bjzc') {
        url += '8021'
    } else if (route == '/zhongzhi') {
        url += '8026'
    } else if (route == '/ntjzc') {
        url += '8018'
    } else if (route == '/nmgzc') {
        url += '8020'
    } else if (route == '/sxzc') {
        url += '8022'
    } else if (route == '/gszc') {
        url += '8023'
    } else if (route == '/sdzc') {
        url += '8024'
    } else if (route == '/fjnp') {
        url += '8025'
    } else if (route == '/hbzc') {
        url += '8027'
    } else if (route == '/szcg') {
        url += '8028'
    } else if (route == '/anhui') {
        url += '8029'
    } else if (route == '/ecg') {
        url += '8030'
    } else if (route == '/jxzy') {
        url += '8031'
    } else if (route == '/rjcg') {
        url += '8032'
    } else if (route == '/ycy') {
        url += '8033'
    } else if (route == '/jdvop') {
        url += '9527'
    } else if (route == '/supplier') {
        url += '8000'
    }
    return url
}
const p = new Promise((resolve, reject) => {
    let data = {
        keys: ["app.child.list"],
    }
    get(api.noLoginGlobal, data).then((res) => {
        resolve(res)
        if (process.env.NODE_ENV == 'development') {
            // 此处为本地添加的微应用，如果线上有该微应用，请删除此处的微应用
            res.data['app.child.list'] = [];
            let obj = [ 
                {
                    name:'chanh_zycg',
                    entry:'',
                    activeRule:'/zycg',
                    container:"#appContainer"
                },
				{
				    name:'chanh_jhyc',
				    entry:'',
				    activeRule:'/bjzc',
				    container:"#appContainer"
				},
                {
                    name:'chanh_zhongzhi',
                    entry:'',
                    activeRule:'/zhongzhi',
                    container:"#appContainer"
                },
				{
				    name:'chanh_ntjzc',
				    entry:'',
				    activeRule:'/ntjzc',
				    container:"#appContainer"
				},
				{
				    name:'chanh_nmgzc',
				    entry:'',
				    activeRule:'/nmgzc',
				    container:"#appContainer"
				},
				{
				    name:'chanh_sxzc',
				    entry:'',
				    activeRule:'/sxzc',
				    container:"#appContainer"
				},
				{
				    name:'chanh_gszc',
				    entry:'',
				    activeRule:'/gszc',
				    container:"#appContainer"
				},
				{
				    name:'chanh_sdzc',
				    entry:'',
				    activeRule:'/sdzc',
				    container:"#appContainer"
				},
				{
				    name:'chanh_fjnp',
				    entry:'',
				    activeRule:'/fjnp',
				    container:"#appContainer"
				},
				{
				    name:'chanh_hbzc',
				    entry:'',
				    activeRule:'/hbzc',
				    container:"#appContainer"
				},
				{
				    name:'chanh_szcg',
				    entry:'',
				    activeRule:'/szcg',
				    container:"#appContainer"
				},
				{
				    name:'chanh_anhui',
				    entry:'',
				    activeRule:'/anhui',
				    container:"#appContainer"
				},
				{
				    name:'chanh_ecg',
				    entry:'',
				    activeRule:'/ecg',
				    container:"#appContainer"
				},
				{
				    name:'chanh_jxzy',
				    entry:'',
				    activeRule:'/jxzy',
				    container:"#appContainer"
				},
				{
				    name:'chanh_rjcg',
				    entry:'',
				    activeRule:'/rjcg',
				    container:"#appContainer"
				},
				{
				    name:'chanh_ycy',
				    entry:'',
				    activeRule:'/ycy',
				    container:"#appContainer"
				},
                {
                    name:"pc",
                    entry:'',
                    activeRule:'/pc',
                    container:"#appContainer"
                },
                {
                    name:"supplier",
                    entry:'',
                    activeRule:"/supplier",
                    container:"#appContainer"
                },
                {
                    name:"collection",
                    entry:'',
                    activeRule:"/collection",
                    container:"#appContainer"
                },
                {
                    name: "jdvop",
                    entry: '',
                    activeRule: '/jdvop',
                    container: "#appContainer"
                }

                // ,
                // {
                //     name: "supplier",
                //     entry: '',
                //     activeRule: "/supplier",
                //     container: "#appContainer"
                // }
            ];
            res.data['app.child.list'] = res.data['app.child.list'].concat(obj)
        }
        jsonapp = [...new Map(res.data['app.child.list'].map(item => [item.name, item])).values()];
        jsonapp.some(item => {
            item.entry = process.env.NODE_ENV == 'development' ? activeRoute(item.activeRule) : item.entry;
            item.activeRule = '/#' + item.activeRule;
            item.props = props
        })
    }).catch((err) => {
        console.log(err);
        reject(err)
    })

})

export const useQiankun = () => {
    p.then(res => {
        // console.log(res)
        const apps = jsonapp;
        console.log(apps)
        registerMicroApps(apps, {
            beforeLoad: [
                app => {
                    console.log(`${app.name}的beforeLoad阶段`)
                }
            ],
            beforeMount: [
                app => {
                    console.log(`${app.name}的beforeMount阶段`)
                }
            ],
            afterMount: [
                app => {
                    console.log(`${app.name}的afterMount阶段`)
                }
            ],
            beforeUnmount: [
                app => {
                    console.log(`${app.name}的beforeUnmount阶段`)
                }
            ],
            afterUnmount: [
                app => {
                    console.log(`${app.name}的afterUnmount阶段`)
                }
            ]
        })
        start({ experimentalStyleIsolation: true, prefetch: true, singular: false })

    })
        .catch(err => {
            console.log(err)
        })
}