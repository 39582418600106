<template>
  <div class="">
    <el-dialog width="50%" title="选择品牌" :visible.sync="brandVisible">
      <div class="modal-body">
        <div class="brand_head">
          <el-input v-model="title" placeholder="请输入搜索的品牌">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.stop="search"
            ></el-button>
          </el-input>
        </div>
        <div class="brand_table">
          <el-table
            :data="brand"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            ref="multipleTable"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              label="品牌名称"
              min-width="100"
              prop="name"
            ></el-table-column>
            <el-table-column label="品牌图标" min-width="150">
              <template slot-scope="scope">
                <div class="brand_icon">
                  <img :src="scope.row.pic_url" alt="">
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="brand_bottom">
          <div>共{{ total }}条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="limit"
            layout="sizes, prev, pager, next, jumper"
            :pager-count="5"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="pricedit()" class="addressqr">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "brandChoice",
  data() {
    return {
      page: 1,
      limit: 10,
      title: "",
      brand: [],
      multipleSelection: [],
      brandVisible: false,
      total: 10,
    };
  },
  watch: {
    brandVisible(value) {
      if (value) {
        this.title = "";
        this.getBrand();
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
      }
    },
  },
  created() {
    // this.getBrand();
  },
  methods: {
    search() {
      if (!this.title) {
        that.$message({
          type: "warning",
          message: "请输入品牌名称",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      this.getBrand();
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getBrand();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.page = val;
      this.getBrand();
    },
    getBrand() {
      let that = this;
      let data = {
        page: this.page,
        limit: this.limit,
        search: [],
      };
      if (this.title) {
        let obj = {
          key: "name",
          value: this.title,
          op: "search",
        };
        data.search.push(obj);
      }
      this.$get(this.$apis.brandSelect, data)
        .then((res) => {
          if (res.code == 200) {
            that.brand = res.data.list;
            that.total = res.data.total;
          } else {
            that.$message({
              type: "warning",
              message: res.msg,
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          }
        })
        .catch((err) => {
          that.common.message(that, err.msg);
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pricedit() {
      let data = this.multipleSelection;
      this.brandVisible = false;
      // console.log(this.multipleSelection)
      this.$emit("change", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-body {
  max-height: 700px;
  overflow-y: auto;
  padding: 14px 0;
  margin: 0 14px;
  .brand_head {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    /deep/.el-input {
      width: 100%;
      // height: 34px;
      // line-height: 34px;
      .el-input__inner {
        // height: 34px;
        // line-height: 34px;
      }
    }
  }
  .brand_table {
    width: 100%;
    margin-bottom: 14px;
  }
  .brand_bottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dialog-footer {
  text-align: center;
}
.brand_icon{
  width: 32px;
  height: 32px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>