<template>
	<div>
		<!-- 修改地址弹层 -->
		<el-dialog title="修改收货地址" class="addresstc" :visible.sync="address">
			<div class="addresstcbox">
				<ul>
					<li class="clearfloat">
						<span><i>*</i> 收件人</span>
						<div class="addressright">
							<input type="text" v-model="addresscon.receiver_name" placeholder="请输入您的姓名" />
						</div>
					</li>
					<li class="clearfloat">
						<span><i>*</i> 电话</span>
						<div class="addressright">
							<input type="text" v-model="addresscon.receiver_mobile" placeholder="请输入您的电话" />
						</div>
					</li>
					<li class="clearfloat">
						<span><i>*</i> 收件人地址</span>
						<div class="addressright clearfloat">
							<template>
								<el-select v-model="addresscon.province" placeholder="省级地区" @change="choseProvince">
									<el-option v-for="(item, index) in chinesecityed" :key="index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
								<el-select v-model="addresscon.city" @change="choseCity" placeholder="市级地区">
									<el-option v-for="(item, $index) in city" :key="$index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
								<el-select v-model="addresscon.district" @change="choseBlock" placeholder="区级地区">
									<el-option v-for="(item, $index) in district" :key="$index" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</template>
						</div>
					</li>
					<li class="clearfloat">
						<span><i>*</i> 详细地址</span>
						<div class="addressright">
							<input type="text" v-model="addresscon.address" placeholder="请输入您的详细地址" />
						</div>
					</li>
				</ul>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="address = false">取 消</el-button>
				<el-button class="addressqr" @click="addresscertitude()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "orderadress",
		props: ["orderconter"],
		data() {
			return {
				address: false, //控制修改地址是否显示
				addresscon: {},
				chinesecityed: [],
				city: [],
				district: [],
			};
		},
		beforeCreate() {},
		created() {},

		mounted() {},

		methods: {
			// 通过id获取name
			getProName(chinesecityed, id) {
				return chinesecityed.find((item) => item.id == id).name;
			},
			getCityName(chinesecityed, id) {
				return chinesecityed.find((item) => item.id == id).name;
			},
			getProvinceName(chinesecityed, id) {
				return chinesecityed.find((item) => item.id == id).name;
			},
			open(val, chinesecity) {
				console.log(val, chinesecity);
				this.address = true;
				if (chinesecity) {
					this.$set(this, "chinesecityed", chinesecity);
				}

				// 根据province 名称找到 provinceId、cityList
				let provinceInfo = this.chinesecityed.find(
					(item) => item.name == val.province
				);
				let provinceId = provinceInfo.id;
				const cityList = provinceInfo.list;
				// console.log("provinceInfo==%o,provinceId==%s,cityList==%o", provinceInfo, provinceId, cityList);
				this.$set(this, "city", cityList);
				// 根据city 名称找到 cityId、distrList
				const cityInfo = cityList.find((item) => item.name == val.city);
				// console.log("cityInfo==%o,city==%s",cityInfo,val.city);
				let distrList = "";
				let distrId = "";
				let cityId = "";
				if (cityInfo && cityInfo.id) {
					cityId = cityInfo?.id ? cityInfo.id : 0;
					distrList = cityInfo.list;
					this.$set(this, "district", distrList);
					// 根据city 名称找到  distrId
					const districtInfo = distrList.find(
						(item) => item.name == val.district
					);
					distrId = districtInfo.id;
				}

				this.$set(this, "addresscon", {
					...val,
					city: cityId,
					district: distrId,
					province: provinceId,
				});
			},
			addresscertitude() {
				const newAddResscon = {
					...this.addresscon,
					province: this.getProName(this.chinesecityed, this.addresscon.province),
					city: this.getCityName(this.city, this.addresscon.city),
					district: this.getProvinceName(this.district, this.addresscon.district),
				};
				// this.addresscon.province = this.getProName(this.chinesecityed, this.addresscon.province);
				// this.addresscon.city = this.getCityName(this.city, this.addresscon.city);
				// this.addresscon.district = this.getProvinceName(this.district, this.addresscon.district);
				this.$emit("adresscertitude", newAddResscon);
			},
			closeattrubute() {
				this.address = false;
			},

			choseProvince(e) {
				const cityList = this.chinesecityed.find((item) => item.id == e).list;
				this.$set(this.addresscon, "city", "");
				this.$set(this.addresscon, "district", "");
				this.$set(this, "city", cityList);
			},

			// 选市
			choseCity(e) {
				// 根据辖区获取地区
				const dist = this.city.find((item) => item.id == e).list;
				this.$set(this.addresscon, "district", "");
				this.$set(this, "district", dist);
			},
			// 选区
			choseBlock(e) {
				this.$set(this.addresscon, "district", e);
			},
		},
	};
</script>

<style>
	.addresstc .el-dialog {
	    width: 600px !important;
	    border-radius: 4px !important;
	}
	
	.addresstcbox {
	    width: 100%;
	    padding: 20px;
	    box-sizing: border-box;
	    background: #fff;
	}
	
	.addresstcbox ul li {
	    width: 100%;
	    margin-bottom: 20px;
	}
	
	.addresstcbox ul li span {
	    float: left;
	    width: 20%;
	    text-align: right;
	    font-size: 14px;
	    color: #333;
	    margin-right: 2%;
	    line-height: 40px;
	}
	
	.addresstcbox ul li span i {
	    color: #f56c6c;
	}
	
	.addressright {
	    float: left;
	    width: 78%;
	}
	
	.addressright input {
	    width: 100%;
	    height: 40px;
	    padding: 0 12px;
	    box-sizing: border-box;
	    font-size: 14px;
	    color: #333;
	    outline: none;
	    border: 1px solid rgba(0, 0, 0, .1);
	    border-radius: 4px;
		transition: all 0.3s;
	}
	.addressright input:hover{border-color: var(--fontColor,#fb6638);}
	.addressright .el-select {
	    float: left;
	    width: 32%;
	    margin-right: 2%;
	}
	
	.addressright .el-select:last-child {
	    margin-right: 0;
	}
	.addressright .el-select .el-input{width: 100%;}
</style>
