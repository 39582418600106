<template>
  <div>
    <el-dialog width="30%" title="选择链接" :visible.sync="linkVisible" append-to-body>
      <div class="modal-body">
        <div class="input-group d-flex">
          <div class="input-group-addon">选择链接</div>
          <el-select
            v-model="value"
            placeholder="请选择"
            value-key="sole_key"
            @change="linkChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <template v-if="value">
          <template v-if="value.params && value.params.length > 0">
            <div class="form-group" v-for="(param, i) in value.params">
              <label
                class="col-sm-2 text-right col-form-label"
                :class="param.required"
                >{{ param.key }}</label
              >
              <div class="col-sm-10">
                <el-input v-model="param.value"></el-input>
                <div class="text-muted" v-if="param.desc">
                  {{ param.desc }}
                </div>
              </div>
            </div>
            <div class="fs-sm text-muted" v-if="is_required">
              <p style="color: red; text-align: center">请填写标记 * 参数</p>
            </div>
          </template>
          <div v-else class="text-muted">此页面无需配置参数</div>
        </template>
        <div class="text-muted" v-else>请选择链接</div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary"  @click="submit">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "linkUrl",
  data() {
    return {
      linkVisible: false,
      options: [],
      value: "",
      is_required: false,
    };
  },
  watch: {
    linkVisible(value) {
      if (value) {
        this.value = "";
        this.is_required=false;
      }
    },
  },
  mounted() {
    this.getLink();
  },
  methods: {
    // 提交数据
    submit() {
    //   console.log(this.value);
      if (this.is_required) {
        return false;
      }
      var value = this.value,
        paramsurl = "",
        link = this.value.link;
      if (value.link != "custom-nav") {
        if (value.params && value.params.length > 0) {
          for (var i in value.params) {
            paramsurl +=
              value.params[i].key +
              "=" +
              encodeURIComponent(value.params[i].value) +
              "&";
          }
        }
        link += "?" + paramsurl;
      } else {
        if (value.params && value.params.length > 0) {
          for (var i in value.params) {
            paramsurl = value.params[i].value;
          }
        }
        // console.log(paramsurl)
        link = paramsurl;
      }
      if(value){
        link = link._trim("&");
        link = link._trim("?");
        value.link = link;
      }
      
      this.linkVisible = false;
      this.$emit("change", value);
    },
    // 选择link
    linkChange(value) {
      this.value = value;
      if (value.params.length) {
        if (value.params[0].required == "required") {
          this.is_required = true;
        } else {
          this.is_required = false;
        }
      }
    },
    getLink() {
      let that = this;
      this.$get(this.$apis.pickLink)
        .then((res) => {
          //   console.log(res);
          if (res.code == 200) {
            if(res.data&&res.data.length){
                for(let i=0;i<res.data.length;i++){
                    res.data[i].sole_key=i;
                }
            }
            that.options = res.data;
          } else {
            that.$message({
              type: "warning",
              message: res.message,
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          }
        })
        .catch((err) => {
          // console.log(err);
          that.common.message(that, err.msg);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px;
  .input-group {
    position: relative;
    width: 100%;
    margin-bottom: 14px;
    .input-group-addon {
      padding: 4.9px 9.8px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: #464a4c;
      text-align: center;
      background-color: #eceeef;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      border-right: 0;
      min-width: 80px;
    }
    /deep/.el-select{
      flex: 1;
    }
  }
  .form-group {
    margin-bottom: 14px;
    display: flex;
    flex-wrap: wrap;
    .col-form-label {
      padding-top: 3.9px;
      padding-bottom: 3.9px;
      text-align: right;
      //   flex: 0 0 16.666667%;
      //   max-width: 16.666667%;
      min-width: 80px;
    }
    .col-sm-10 {
      //   padding-right: 15px;
      padding-left: 25px;
      flex: 1;
      /deep/.el-input {
        // height: 32px;
        // line-height: 32px;
        // .el-input__inner {
        //   height: 32px;
        //   line-height: 32px;
        // }
      }
      .text-muted {
        color: #636c72;
        font-size: 12px;
        text-align: left;
      }
    }
  }

  .text-muted {
    color: #636c72;
    text-align: center;
  }
}
</style>