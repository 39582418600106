<template>
  <div class="goods_seek">
    <div
      v-for="(item, index) in ruleForm.list"
      :key="index"
      class="inline-block goods_selected clearfloat"
    >
      <template v-if="ruleForm.advanced_length > index">
        <searchInput :params="item"></searchInput>
      </template>
      <template v-else>
        <template v-if="ruleForm.is_advanced">
          <searchInput :params="item"></searchInput>
        </template>
      </template>
    </div>
    <div class="high_class inline-block" v-if="ruleForm.advanced_length||ruleForm.btn">
      <div
        class="high_headline"
        v-if="ruleForm.advanced_length"
        @click="ruleForm.is_advanced = !ruleForm.is_advanced"
      >
        <span>高级筛选</span>
        <i class="el-icon-arrow-down" v-if="!ruleForm.is_advanced"></i>
        <i class="el-icon-arrow-up" v-else></i>
      </div>
      <div class="goodsbtn" @click="searchCilck(item)">搜索</div>
      <div class="goodsbtn attr_foot_cancel">重置条件</div>
      <template v-if="ruleForm.btn && ruleForm.btn.length">
        <div class="inline-block search_btn">
            <div
            v-for="(item, index) in ruleForm.btn"
            :key="index"
            :class="['goodsbtn', item.is_main ? 'attr_foot_cancel' : '']" @click="searchCilck(item)"
          >
            {{ item.name }}
          </div>
        </div>
        
      </template>
    </div>
  </div>
</template>
<script>
import searchInput from "./searchInput";
import axios from "axios"
export default {
  name:"goodSearch",
  props: ["rulseForm", "rulse"],
  components: {
    searchInput,
  },
  data() {
    return {
      ruleForm: this.rulseForm ? this.rulseForm : {},
      rulesRules: this.rulse,
    };
  },
  created() {
    // console.log(this.rulseForm)
  },
  methods: {
    searchCilck(item){
      if(item.action=='redirect'){
          this.$router.push(""+item.url)
      }else if(item.action=='action'){
        this.executeRequest(item.api,item.api_method)
      }else if(item.action=='window_page'){
          // 打开弹窗
      }
    },
    executeRequest(url,methods){
      let params=[];
      let list=this.ruleForm.list;
      for(let i in list){
        params.push({
          key:''+list[i].key,
          value:''+list[i].parameters.inputName,
          op:''+list[i].form
        })
      }
      console.log(params)
      // axios[methods](url).then(res=>{
      //   console.log(res)
      // })
    },
  },
};
</script>
<style>
.goods_seek {
  display: table;
  word-spacing: -1em;
  width: 100%;
  height: 100%;
}

.goods_seek > .inline-block {
  padding-top: 20px;
  /* margin-right: 20px; */
}
.orderfirstleft {
  float: left;
}

.goods_seek > .inline-block > span {
  padding: 0 10px;
  width: 90px;
  display: inline-block;
  text-align: right;
  font-size: 14px;
}

.search_headline {
  min-width: 274px;
  height: 38px;
}

.search_headline >>>.el-input .el-input__inner {
  /* height: 38px; */
  border-radius: 4px;
}

.search_headline>>> .el-input__inner:focus {
  border: 1px solid #000c9a;
  border-radius: 4px;
}

.search_headline >>>.el-input::-webkit-scrollbar-thumb {
  border: 1px solid #000c9a;
  border-radius: 4px;
}
.goods_selected>>> .search_headline .el-input__inner {
  border-radius: 0 4px 4px 0;
}
.goods_selected >>>.search_headline .el-input__inner:focus {
  border: 1px solid #000c9a;
  border-radius: 0 4px 4px 0;
}
.search_headline>>> .el-select {
  width: 270px;
}

.search_headline .el-select.is-focus {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.search_headline .el-select:focus {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.search_headline .el-select:hover .el-input__inner {
  border: solid 0.5px var(--fontColor);
  border-radius: 4px;
}

.goods_sales {
  width: 117px;
  height: 38px;
}

.goods_sales .el-input__inner {
  /* height: 38px; */
}

.search_headline > span {
  padding: 0 10px;
  width: auto;
  display: inline-block;
  text-align: right;
  font-size: 14px;
}

.high_class .goodsbtn {
  margin-right: 8px;
}

.high_headline {
  display: inline-block;
  line-height: 40px;
  color: var(--fontColor);
  font-size: 14px;
  cursor: pointer;
  /* margin-left: 10px; */
  margin-right: 10px;
}

.high_headline > span {
  width: 88px;
  text-align: right;
  display: inline-block;
}
.high_headline > i {
  margin-left: 4px;
}
.high_sarch {
  background: var(--fontColor);
  color: #fff;
  padding: 0 16px;
  height: 38px;
  font-size: 14px;
  line-height: 38px;
  display: inline-block;
  border-radius: 4px;
  margin-right: 8px;
}

.high_class .high_sarch:hover {
  background: var(--fontColor);
  color: #fff;
}

.high_class .high_sarch:focus {
  background: var(--fontColor);
  color: #fff;
}

.reset_sarch {
  padding: 0 16px;
  height: 38px;
  font-size: 14px;
  margin-left: 0 !important;
}

.high_class .reset_sarch:hover {
  background: #fff;
  color: #606266;
}

.high_class .reset_sarch:focus {
  background: #fff;
  color: #606266;
}
.search_btn{
  margin-left: 10px;
}
</style>