<!-- Layout  -->
<template>
  <div class="layout">
    <!-- navleft -->
    <div class="sidebar">
      <Navleft @handleRouteLink="handleRouteLink"></Navleft>
    </div>
    <div class="main-container">
      <!-- head -->
      <Header></Header>
      <!-- secondNav -->
      <!-- 首页不需要 v-if="$route.fullPath !='/'"-->
      <secondNav :secondList="route" :navtitle="title" v-if="$route.fullPath != '/'" :newpath="path"></secondNav>
      <!-- app-main -->
      <AppMain></AppMain>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header";
import Navleft from "@/components/navleft";
import AppMain from "./components/AppMain.vue";
import secondNav from "@/components/secondNav";
import { mapGetters } from "vuex";
import { Message } from "element-ui";
export default {
  name: "Layout",
  components: {
    AppMain,
    Header,
    Navleft,
  },
  data() {
    return {
      route: [], //二级路由
      title: "", //二级路由tite
      menuMap: {},
      path: "",
      routeMap: {},
    };
  },
  async mounted() {
    // await this.getNoLoginGlobal()
    this.getMenuList();
    // if (this.menuLists) {
    //     if (this.$route.fullPath) {
    //         if (this.$route.fullPath.lastIndexOf("/") != this.$route.fullPath.indexOf("/")) {
    //             let subString = this.$route.fullPath.substring(0, this.$route.fullPath.lastIndexOf("/"));
    //             if (subString.indexOf("/") != subString.lastIndexOf("/")) { //判断多级
    //                 subString = subString.substring(0, subString.lastIndexOf("/"));
    //                 const refrenChildren = this.changeMenuList2Map(this.menuLists).find(item => item.route == (subString));
    //                 if (refrenChildren) {
    //                     this.$nextTick(() => {
    //                         this.$set(this, "route", refrenChildren.children);
    //                         this.$set(this, "title", refrenChildren.title);
    //                     });
    //                     this.$forceUpdate();
    //                     console.log("refrenChildren-1", refrenChildren);
    //                 }
    //             } else {
    //                 const refrenChildren = this.changeMenuList2Map(this.menuLists).find(item => item.route == (subString));
    //                 if (refrenChildren) {
    //                     this.$nextTick(() => {
    //                         this.$set(this, "route", refrenChildren.children);
    //                         this.$set(this, "title", refrenChildren.title);
    //                     });
    //                     this.$forceUpdate();
    //                 }
    //                 console.log("refrenChildren-2", refrenChildren);
    //             }
    //         } else {
    //             const refrenChildren = this.changeMenuList2Map(this.menuLists).find(item => item.route == (this.$route.fullPath));
    //             this.$nextTick(() => {
    //                 this.$set(this, "route", refrenChildren.children);
    //                 this.$set(this, "title", refrenChildren.title);
    //             });
    //             this.$forceUpdate();
    //         }
    //     }
    // }
  },
  watch: {
    watchData: {
      handler(newVal) {
        // console.log("当前路由==%s", newVal.fullPath);
        // console.log("当前路由父路由是否在MenuList中", Object.keys(newVal.menuLists).some(item => item.includes((newVal.fullPath != '/' && this.subStringRoute(newVal.fullPath).replace('/', '')))));
        // pc、collections路由需要判断
        // newVal.fullPath.includes('/pc')
        // if (Object.keys(newVal.menuLists).some(item => item.includes((newVal.fullPath != '/' && this.subStringRoute(newVal.fullPath).replace('/', ''))))) {
        //     this.$store.commit('SET_MENU_TYPE', this.subStringRoute(newVal.fullPath).replace('/', ''))
        // } else {
        //     this.$store.commit('SET_MENU_TYPE', "base");
        // }
        let path = this.$route.path, pathName = this.$route.name;
        let map = this.routeMap;
        if (map && map[pathName]) {
          path = map[pathName];
        }
        this.path = path;
        let menuType = newVal.menuType;
        if (this.menuMap[newVal.fullPath]) {
          if(path=='/app/index'){
            menuType = this.menuMap[path][this.menuMap[path].length-1];
            if(menuType.indexOf('/')!=-1){
              menuType = menuType.split('/')[1]
            }
          }else{
            menuType = this.menuMap[path][0];
          }
          
          
        } else {
          for (let i in this.menuMap) {
            if (path.indexOf(i) >= 0) {
              menuType = this.menuMap[i][0];
            }
          }
        }
        this.$store.commit("SET_MENU_TYPE", menuType);
        if (newVal && newVal.menuLists[menuType]) {
          if (this.$route.fullPath) {
            let subString = this.subStringRoute(this.$route.fullPath);
            if (menuType == "base") {
              const refrenChildren = this.changeMenuList2Map(
                newVal.menuLists[menuType]
              ).find((item) => item.route == subString);
              let refchildre = refrenChildren ? this.handleRouteActive(JSON.parse(JSON.stringify(refrenChildren))) : '';
              // console.log(refrenChildren.children,refchildre.children)
              this.$nextTick(() => {
                this.$set(this, "route", refrenChildren && refchildre.children);
                this.$set(this, "title", refrenChildren.title);
              });
              this.$forceUpdate();
            } else {
              const routeArr = newVal.menuLists[menuType].map((item) => ({
                permission_name: item.permission_name,
                route: item.route,
                children: item.children,
                group: item.group,
              }));
              const refrenChildren = this.changeMenuList2Map(routeArr).find(
                (item) => item.route == subString
              );
              let refchildre = refrenChildren ? this.handleRouteActive(JSON.parse(JSON.stringify(refrenChildren))) : '';
              this.$nextTick(() => {
                this.$set(
                  this,
                  "route",
                  refrenChildren && refchildre.children
                );
                this.$set(
                  this,
                  "title",
                  refrenChildren && refrenChildren.title
                );
              });
              this.$forceUpdate();
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 截取第一级路由
    subStringRoute(routePath) {
      if (routePath.lastIndexOf("/") != routePath.indexOf("/")) {
        let subString = routePath.substring(0, routePath.lastIndexOf("/"));
        if (subString.indexOf("/") != subString.lastIndexOf("/")) {
          //判断多级
          subString = this.subStringRoute(subString);
        }
        return subString;
      } else {
        return "/";
      }
    },
    formatdd(data, arr, type) {
      for (let i in arr) {
        data[arr[i]["route"]] = type;
        if (arr[i]["children"].length > 0) {
          let tmp = JSON.parse(JSON.stringify(type));
          tmp.push(arr[i]["route"]);
          data = this.formatdd(data, arr[i]["children"], tmp);
        }
      }
      return data;
    },
    // 获取菜单列表
    getMenuList() {
      this.$get(this.$apis.MenuList).then((res) => {
        if (res.code == 200) {
          this.routeMap = res.data['route_mapping'];
          // this.$set(this, 'navList', res.data.base);
          // this.$store.commit('GET_MENU_LIST', res.data.base);
          let data = {};
          for (let i in res.data) {
            if (i != 'route_mapping') {
              data = this.formatdd(data, res.data[i], [i]);
            }
          }
          this.menuMap = data;
          this.$store.commit("GET_MENU_LIST", res.data);
        } else {
          Message.error(`获取菜单列表失败!-[${res.message}]`);
        }
      });
    },
    // 改变menuList格式 --->  [{route:children}]
    changeMenuList2Map(list) {
      if (Array.isArray(list)) {
        const arr = list.reduce((pre, ite) => {
          const obj = {};
          obj["route"] = ite["route"];
          obj["children"] = ite["children"];
          obj["title"] = ite["permission_name"];
          pre.push(obj);
          // 判断是否存在children
          if (ite.hasOwnProperty("children")) {
            // 转换格式
            pre = [...pre, ...this.changeMenuList2Map(ite.children)];
            return pre;
          }
          return pre;
        }, []);
        return arr;
      } else {
      }
    },
    handleRouteLink(title, route) {
      // 重定向到route的第一个
      this.$set(this, "title", title);
      this.$set(this, "route", route);
    },
    handleRouteActive(route) {
      let path = this.$route.fullPath, pathName = this.$route.name, map = this.routeMap;
      if (map && map[pathName]) {
        path = map[pathName];
      }
      if (route.children.length) {
        route.children.forEach(item => {
          if (item.route == path) {
            route.secondClickRoute = true;
          }
          if (item.children.length) {
            this.handleRouteActive(item);
          }

        })

      }
      return route;
    },
  },
  computed: {
    // ...mapGetters(['menuLists']),
    // ...mapGetters(['menuType']),
    watchData() {
      const { menuLists, menuType } = this.$store.getters;
      const { fullPath } = this.$route;
      return { menuLists, menuType, fullPath };
    },
  },
};
</script>

<style scoped lang="less">
.layout {
  width: 100%;
  display: flex;

  & .sidebar {
    z-index: 99;
  }

  .main-container {
    width: calc(100% - 106px);
  }
}
</style>