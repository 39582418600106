<template>
  <div class="boxleft"  v-if="headerstyle">
    <div class="boxnav">
      <ul>
        <li v-for="(item, index) in menuLists.base" :key="item.id" :class="isChangeRoute(item.route) ? 'active' : ''"
          @click="handleRouteLink(item.permission_name, item.children,item.route,item.group)">
          <a href="javascript:void(0)">
            <i :class="item.icon"></i>{{ item.permission_name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "navLeft",
  props: {},
  data() {
    return {
		headerstyle: false,
	};
  },
  created() { },
	mounted() {
		let styletype = window.localStorage.getItem("style");
		this.headerstyle = styletype == 1 ? false : true;
	},
  methods: {
    // 判断当前路由是否等于当前点击的slider
    isChangeRoute(route) {
      if (this.$route.fullPath.lastIndexOf("/") != this.$route.fullPath.indexOf("/")) {
        let subString = this.$route.fullPath.substring(0, this.$route.fullPath.lastIndexOf("/"));
        if (subString.indexOf("/") != subString.lastIndexOf("/")) { //判断多级
          subString = subString.substring(0, subString.lastIndexOf("/"));
          return route == subString;
        }
        return route == subString;
      } else {
        return this.$route.fullPath == route;
      }
    },

    handleRouteLink(title, children,route, group) {
      console.log(route);
      // 自定义事件，用于动态渲染二级页面导航栏
      this.$emit("handleRouteLink", title, children);
      // 修改menuType
      this.$store.commit("SET_MENU_TYPE", group);
      this.$router.push(route == '/' ? route : (children[0].hasOwnProperty('children') && children[0].children.length > 0) ? children[0]['children'][0].route  : children[0].route)
    },
  },
  computed: {
    ...mapGetters(['menuLists'])
  }
};
</script>

<style scoped>
	.boxleft {
	    width: 106px;
	    height: 100vh;
	    background: var(--bgColor, #1e222b);
	    border-right: 1px solid #eee;
	    box-sizing: border-box;
	    z-index: 100;
	    padding-top: 60px;
	    position: fixed;
	}
	
	.boxnav {
	    width: 100%;
	}
	
	.boxnav ul li {
	    width: 100%;
	    height: 50px;
	    line-height: 50px;
	    text-align: center;
	    position: relative;
	    transition: all 0.3s;
	}
	
	.boxnav ul li a {
	    display: block;
	    width: 100%;
	    color: var(--navFontDefault, #d8d7d7);
	    border-right: 3px solid hsla(0, 0%, 100%, 0);
	    font-size: 18px;
	    transition: all 0.3s;
	    box-sizing: border-box;
	}
	
	.boxnav ul li a i {
	    font-size: 16px;
	    margin-right: 10px;
	}
	
	.boxnav ul li.active a {
	    border-color: var(--navFontActive, #fb6638);
	    color: var(--navFontActive, #fb6638);
	    background: var(--navFontActiveBg, transparent);
	}
	
	.boxnav ul li:hover a {
	    border-color: var(--navFontActive, #fb6638);
	    color: var(--navFontActive, #fb6638);
	    background: var(--navFontActiveBg, transparent);
	}
</style>
