export default {
	groupList: '/group/list',//商品分组
	supportList: '/label/label_list',//标签列表
	brandList: '/brand/list',//品牌列表
	imgList: '/file/image/list',//图片选择器列表
	imgGroup: '/file/group',//图片选择器分组
	pcLogin: '/login', //登录
	refresh: '/token/refresh',//刷新token
	quit: '/logout/',//退出
	addGroup: '/group/group_add',//新增商品分组
	addSupport: '/label/label_add',//新增标签
	addBrand: '/brand/brand_add',//新增品牌
	supportAlldelect: '/label/label_batch_del',//批量删除
	addImgroup: '/file/group',//创建图片选择器组
	editImgroup: 'file/group/',//编辑图片分类
	loopBrand: '/brand/brand_detail/',//查看品牌详情
	brandedit: '/brand/brand_edit/',//编辑品牌报存
	groupedit: 'group/group_edit/',//编辑商品分组
	supportedit: '/label/label_edit/',//编辑标签
	imgGroupedit: '/file/group/',//编辑图片选择器分组
	groupDelete: '/group/group_del',//删除商品分组
	branDelete: '/brand/brand_del/',//删除品牌
	branDeletebatch: '/brand/brand_del_batch/',//批量删除品牌
	brandexist: '/brand/exist/',//判断品牌是否存在
	groupimgDelete: '/file/group/',//删除图片选择器组
	imglistDelete: '/file/files',//删除图片选择器图片
	batchGoodsBrand:"/goods/goods_brand",	//商品列表批量修改品牌
	batchGoodsStock:"/goods/goods_stock",	//商品列表批量修改库存
	editExpress:"/order/express_edit/",	//修改物流信息
	//?员工角色接口
	roleList: '/permission/role',//角色列表页
	meNu: '/permission_all_list',//角色编辑新增页面的列表
	addiTion: '/permission/user',//员工列表
	increAsed: '/permission/user',//员工新增
	addRole: '/permission/role',//添加角色
	deletrole: '/permission/role',//删除角色
	deletStaff: '/permission/user/',//删除员工
	edIa: '/permission/user',//编辑员工
	obtain: '/permission/role_all_list',//获取所有角色
	xpassWord: '/permission/user_password',//修改密码
	editRole: '/permission/role',//修改角色
	ingleRole: '/permission/role',//单个角色信息

	//三方服务接口
	switchk: '/setting/option/trd_service',//获取开关按钮
	swutrtext: '/setting/option/sms',//获取短信详情接口
	editswutrtext: '/setting/option/sms',//修改短信详情接口
	objecooke: '/setting/option/file_storage',//获取对象存储接口
	exprekd: '/setting/option/express',//获取物流接口信息
	mailed: "/setting/option/mail",//获取邮件信息
	upImg: '/file/image',//上传图片
	infoList: '/cat/info/',//分类列表/删除分类
	catparentList: '/cat/parents/',//获取父级分类信息
	argumentsList: '/cat_param/param_list/',//商品参数列表
	argumentDelete: '/cat_param/param_del/',//删除商品参数
	catList: '/cat/all/',//商品参数分类
	loopPara: '/cat_param/param_detail/',//查看商品参数
	paramEdit: '/cat_param/param_edit/',//编辑商品参数
	addParam: '/cat_param/param_add/',//新增商品参数
	spuList: '/spu/list/',//获取spu列表
	spuDetail: '/spu/spu_detail/',//查看spu详情
	spuEdit: '/spu/spu_edit_preview/',//编辑spu预览
	goodsList: '/goods/list/',//商品列表
	orderList: '/order/list/',//订单列表
	resource: '/resources/config',//资源接口（需要登陆）
	grounding: '/goods/goods_on',//商品上架接口
	offshelf: '/goods/goods_off',//商品下架接口
	goodsDelete: '/goods/goods_del/',//商品删除接口
	setHot: '/goods/goods_hot/',//商品批量设置取消热卖
	setBest: '/goods/goods_best/',//商品批量设置取消推荐
	allPrice: '/goods/goods_price/',//商品批量改价
	allGroup: '/goods/goods_group/',//商品批量改分组
	modifyGoodname: '/goods/edit_name/',//修改商品名称
	allCat: '/goods/goods_cat/',//批量设置分类
	goodSku: '/goods/set_sku/',//商品详情商品货号
	addGoods: '/goods/goods_add/',//新增商品
	goodsEdit: '/goods/goods_detail/',//查看商品详情
	goodsEduted: '/goods/goods_edit/',//编辑报存接口
	recycle: '/recycle/list/',//回收站列表
	recycleDelete: '/recycle/recycle_del/',//回收站删除
	recycleRestore: '/recycle/recycle_restore/',//回收站恢复
	shoptcList: '/select/goods/',//商品选择器商品列表
	attrEdit: '/spu/spu_edit/',//商品spu编辑报存接口
	addshopspu: '/spu/spu_add/',//商品spu新增报存接口
	delectAttr: '/spu/spu_del_goods/',//删除SPU绑定的商品
	addspu: '/spu/spu_preview/',//商品绑定spu预览
	addservice: '/content/article_type_search/',//售后保障，包装清单
	servicelabel: '/label/label_type_list/',//服务和标签
	goodslog: '/operate/log/goods/',//商品操作日志

	// 消息通知接口
	dtavsq: '/setting/option/sms_notify',//获取短信通知列表


	// 装修模快
	pickLink: "/pc/diy/get_pick_link",	//链接选择器
	brandSelect: "/pc/select/brand",	//品牌选择器
	getElemnt: "pc/diy/get_modules_list",	//获取模块列表
	savefinish: "pc/diy/template",	//保存装修模块||获取模板列表
	temSetIndex: "pc/diy/",	//设置为首页
	delTemPlate: "pc/diy/template/",	//删除模板
	pickClass: "pc/select/category",	//装修中所需的分类列表
	templatExtend: "pc/diy/",	//获取模板扩展信息

	//会员模块接口
	memberUsers: '/user/user', //会员用户列表
	memberUserFreezeAndrescind: '/user/user_freeze', //会员冻结、解绑
	memberModifyPwd: '/user/user_password', //修改密码
	memberBalance: '/user/user_account/recharge',//增加/修改余额
	memberUserLevel: '/user/level',//会员等级列表
	memberLevel: '/user/level',//会员等级
	memberAdvanceUpdate: '/setting/option/member_advance_setting',//会员升级设置
	memberUserBlackList: '/user/user_blacklist',//拉黑用户
	levelIsExistApi: '/user/level_is_exist',//判断当前会员等级是否存在
	levelNameIsExistApi: '/user/level_name_is_exist',//会员名称是否存在

	// 财务模块接口
	financeRecordApi: '/finance/re_order',//充值记录列表
	finaceUserAccountApi: '/finance/user_account_log',//余额流水列表

	//获取所有管理愿列表
	permissonUserList: '/permission/user_all_list',

	// 文章分类列表
	contentArticleClassApi: '/content/article_cat',
	// 单个文章分类详情
	ownArticleCatInfo: '/content/article_cat/',
	// 订单
	orderattrbuite: '/order/remarks/',//订单备注
	cityList: '/district_list/',//省市区接口
	loopdelivery: '/order/to_send/',//查看订单可发货商品信息
	deliveryopen: '/order/send_submit/',//发货提交按钮
	deliveryopenNew: '/order/send_submit_new/',//发货提交按钮(新)
	orderoperate: '/operate/log/order/',//订单日志
	orderDetail: '/order/order_detail/',//订单详情
	addressedit: '/order/address/',//订单修改收货地址
	ordertrack: '/order/track/',//订单详情物流信息
	// 售后订单
	refund: '/aftersale/list/',//售后列表
	exchange: '/aftersale/handle/',//同意/拒绝退换货
	seaddress: '/aftersale/aftersale_address/',//获取售后地址
	servicedetails: '/aftersale/aftersale_detail/',//售后订单详情
	aftereceipt: '/aftersale/aftersale_receipt/',//确认收货
	aftereship: '/aftersale/send/',//重新发货
	refundtk: '/aftersale/aftersale_refund/',//售后退款
	// 订单评价
	evaluate: '/comment/list/',//评价列表
	commenthide: '/comment/hidden/',//评论隐藏
	commentdelect: '/comment/delete/',//删除评论
	commentreview: '/comment/comment_review/',//批量审核通过
	commentreply: '/comment/comment_reply/',//评论批量回复
	commentdetail: '/comment/comment_detail/',//评论详情
	// 发票
	invoicelist: '/invoice/list/',//发票列表
	invoicenumber: '/invoice/invoice_code/',//设置发票号
	invoicepexpress: '/invoice/invoice_express/',//发票发货
	invoiceupload: '/invoice/invoice_upload/',//上传发票
	invoicedetail: '/invoice/invoice_detail/',//发票详情
	// 订单售后设置
	setservice: '/setting/option/order_refund/',//获取订单退换货配置信息
	setservicesave: '/setting/option/order_refund/',//编辑订单退款货配置

	// 文章管理列表
	contentArticleApi: '/content/article',
	hbtntz: '/setting/option/email_notify',//获取邮件通知列表
	falytz: '/setting/option/sms_notify',//获取消息通知控制按钮
	artfalytz: '/setting/option/sms_notify',//修改通知按钮
	articleTypeApi: '/content/article_type', //编辑文章类型
	// 通用设置接口
	commonMemberApi: '/setting/option/common_member',//获取修改会员
	// 获取和修改订单设置
	commonOrderApi: '/setting/option/common_order',// 获取和修改订单设置
	commonGoodApi: '/setting/option/common_goods',// 获取和修改商品设置
	commonShowApi: '/setting/option/common_show',//获取修改显示设置接口
	ment: '/setting/option/alipay',//获取修改支付宝信息
	yliaw: '/setting/option/union_pay_btc',//获取修改银联网关
	newyliaw: '/setting/option/union_pay_btb',//获取修改新银联网关
	newffline: '/setting/option/offline_pay',//获取修改线下支付
	wxment: '/setting/option/wechat_pay',//获取修改微信支付
	switchTypesApi: '/setting/option/pay_service',//支付服务状态
	switchNotifyApi: '/setting/option/notify_service',//消息通知状态

	// 获取菜单列表
	MenuList: '/menu_list',

	// 修改密码-个人
	modifyPwd: '/user_password',
	// 消息中心
	noticeList: '/message/get_message_list',
	// 设置所有的消息已读
	allReadmessage: '/message/all_read_message',
	// 升级优化->清除缓存
	clearCache: '/setting/option/clear_cache',
	// 升级优化 -> 系统更新 -> 信息列表
	sysUpdateInfo: '/cloud/version/data',
	// 升级优化 -> 系统更新 -> 进行更新
	sysUpdateRenew: '/cloud/version/renew',
	// 设置 -> 员工管理 -> 操作日志 -> 操作人 只有id和名字
	sysStaffLogIdName: '/permission/user_operator_list',
	// 设置 -> 员工管理 -> 操作日志 -> 列表
	sysOperateLog: '/operate/log',
	// 设置 -> 员工管理 -> 操作日志 -> 查看详情
	sysOperateLogDetail: '/operate/log/',
	// 应用 -> 应用插件 -> 列表
	appPluginList: '/cloud/plugin/list',
	// 应用 -> 应用插件 -> 插件详情
	appPluginDetail: '/cloud/plugin/detail/',
	// 应用 -> 应用插件 -> 购买下单
	appPluginBuy: '/cloud/plugin/create_order/',
	// 应用 -> 应用插件 -> 应用安装
	appPluginInstall: '/cloud/plugin/install/',
	// 应用 -> 应用插件 -> 应用升级
	appPluginUpdate: '/cloud/plugin/renew/',
	// 应用 -> 权限 -> 添加邀请码
	appPluginSecret: '/cloud/authorize',
	// 全局未登录资源
	noLoginGlobal: '/resources/global',
	// 提交反馈
	setFeedback: '/cloud/feedback',
	// 设置 -> 敏感词库列表
	getSensitive: '/content/sensitive_words',
	// 设置 -> 添加敏感词库
	addSensitive: '/content/sensitive_words',
	// 设置 -> 编辑敏感词库
	editSensitive: '/content/sensitive_words/',
	// 应用 -> 采集助手 -> 单个采集
	singleAppCollection: '/collection/collection/',
	// 应用 -> 采集助手 -> 模板导入
	importAppCollection: '/collection/import/',
	// 获取采集助手默认资源
	configCollection: '/collection/resources/config',
	// 获取采集助手设置
	settingCollection: '/collection/setting',
	// 获取采集助手日志
	logCollection: '/collection/log',
	// 首页获取是否有系统更新消息
	sysUpdateMessage: '/message/get_sys_msg',
	// 设置首页弹窗是否已读
	setUpdateMessageRead: '/message/read_sys_msg',

	modifyPwd: '/user_password',

	//平台设置
	platSettingApi: '/setting/option/platform_setting',
	// 平台设置-后台设置
	backSettingApi: '/setting/option/platform_backend_setting',
	code: '/code',

	//渠道设置
	getPcSeverice: "pc/option/customer_service",	//获取客服设置 
	getPcPcBottom: "pc/option/pc_bottom",	//获取底部设置
	getPcTopHead: "pc/option/pc_top",	//获取顶部设置
	getPcTrench: "pc/option/pc_base",	//获取pc渠道配置
	getChannelList: "cloud/plugin/client",	//渠道首页

	// 配送规则
	deliveryRuleApi: '/setting/delivery/rules', //获取运费模版
	// 包邮规则
	parcelapi:"/setting/free_delivery_rules/",//包邮规则
	parceldelect: "/setting/free_delivery_rules/",//删除包邮规则
	loopparce:"/setting/free_delivery_rules/",//查看包邮规则
	// 退货地址Api
	refundAddressApi: '/setting/refund_address',

	// 政采接口
	zcgoodlist: '/yangcai/goods/list',//中央采购商品列表
	zcbrandlist: "/yangcai/brand/list",//中央采购品牌列表
	upbrandlist: "yangcai/brand/sync",        //更新中央采购品牌列表
	zcClasslist: "/yangcai/category/list",        //中央采购分类列表
	zcgroundingon: '/yangcai/goods/goods_on/',//商品单个上架
	zcgroundingoff: '/yangcai/goods/goods_off/',//商品单个下架
	zcgroundon: '/yangcai/goods/goods_on_batch/',//商品批量上架
	zcgroundoff: '/yangcai/goods/goods_off_batch/',//商品批量下架
	zcenergyon: '/yangcai/goods/energy_on/',//商品设置节能
	zcenergyoff: '/yangcai/goods/energy_off/',//商品关闭节能
	zcprotectionon: '/yangcai/goods/protection_on/',//商品设置环保
	zcprotectionoff: '/yangcai/goods/protection_off/',//商品关闭环保
	zcupsert: '/yangcai/goods/upsert/',//单个商品同步
	zcupsertbatch: '/yangcai/goods/upsert_batch/',//商品批量同步
	zcdelect: '/yangcai/goods/delete/',//单个商品删除
	zcmodifyprice: '/yangcai/goods/price/',//修改商品售价
	zcmodifystock: '/yangcai/goods/stock/',//修改商品库存
	zcbrandbatch: '/yangcai/goods/brand_batch/',//批量映射商品品牌
	setZycgGoodAttr:"/yangcai/goods/params/",	//设置商品属性
	setBathZycgAttr:"yangcai/goods/params_batch",	//批量设置商品属性
	lookGoodAttr:"/yangcai/goods/params_detail/",	//查看该商品属性
	editGoodsttr:"yangcai/goods/params_detail_edit/",	//更改商品属性
	zcpacking: '/yangcai/packing/list/',//包装清单列表
	zcpackingAdd: '/yangcai/packing/store',	//新增包装清单
	zcpackingEdit: "/yangcai/packing/edit/",	//编辑包装清单
	zcpackingDel: "/yangcai/packing/delete/",	//删除中央采购的包装清单
	zcpackbatch: '/yangcai/goods/packing_batch/',//批量映射包装清单
	zcaddgoods: '/yangcai/goods/batch_add/',//批量增加商品
	zcClasslist:"/yangcai/category/list",	//中央采购分类列表
	upclasslist:"/yangcai/category/sync",	//更新中央采购分类列表
	zcattribute:"/yangcai/params/list",	//中央采购分类属性
	delzycgbute:"/yangcai/params/destroy/",	//删除中央采购分类属性
	zycgby_category:"yangcai/params/list_by_category/",	//获取央财类目属性值
	zycg_category_new:"yangcai/params/store",	//分类属性新增
	lookCategory:"yangcai/params/show/",//查看分类属性
	redactCategory:"/yangcai/params/edit/",	//编辑中央采购属性
	zycgargementGood:"/yangcai/option/show",	//中央采购的商品参数
	zycgargementGoodEdit:"/yangcai/option/edit",	//中央采购的商品参数设置
	zycgGoodDetail:"yangcai/goods/detail/",	//中央采购商品详情
	zycgGoodAdd:"/yangcai/goods/add",//中央采购新增商品
	zycgGoodEdit:"/yangcai/goods/edit/",	//中央采购编辑商品
	zycgGoodSku:"/yangcai/goods/set_sku",	//中央采购商品货号
	zycgGoodBinList:"/yangcai/recycle/list",	//中央采购商品回收站
	zycgGoodRenew:"/yangcai/recycle/restore/",	//中央采购商品回收站恢复商品
	zycgGoodRenewBatch:"/yangcai/recycle/restore_batch",	//中央采购商品回收站批量恢复商品
	zycgGoodDel:"/yangcai/recycle/destroy/",	//中央采购商品回收站删除商品
	zycgGoodDelBatch:"/yangcai/recycle/destroy_batch",	//中央采购商品回收站批量删除商品
	zycgOrderDetail:"/yangcai/order/order_detail/",	//中央采购订单详情
	zycgOederTrack:"/yangcai/order/track/",	//中央采购物流详情
	zycgOrderRemark:"/yangcai/order/order_remark/",	//中央采购订单详情修改备注
	zycgOrderExpress:"/yangcai/express_list",	//中央采购物流公司列表
	zycgOrderAccessory:"yangcai/order/attachment_download/",	//中央采购订单详情附件
	zycgOrdersend_submit:"/yangcai/order/send_submit/",	//中央采购的发货
	zycgGoodgather:"/yangcai/goods/import",	//中央采购一键采集
	zycgGoodgatherSingle:"yangcai/goods/import_one",	//中央采购单个商品采集
	// 中央采购 发票接口板块
	zycgInvoiceList: '/yangcai/invoice/list',//发票列表
	zycgInvoiceNmber: '/yangcai/invoice/invoice_code/',//设置发票号
	zycgInvoicePexpress: '/yangcai/invoice/invoice_express/',//发票发货
	zycgInvoiceUpload: '/yangcai/invoice/invoice_upload/',//上传发票
	zycgInvoiceDetail: '/yangcai/invoice/invoice_detail/',//发票详情

	// 中央采购 订单接口板块
	zycgOrderList: '/yangcai/order/list',
	zycgoodlogs: '/yangcai/goods/log/',//商品日志
	// 订单可发货商品信息
	zycgOrderSendDetail: '/yangcai/order/to_send/',
	// 订单可发货物流
	zycgOrderExpress: '/yangcai/express_list',
	// 订单记录设置备注
	zycgOrderRemark: '/yangcai/order/order_remark/',
	// 订单记录设置签收方式
	zycgOrderQianshou: '/order/sign_notice/',
	// 订单记录确认支付
	zycgOrderConfirmPay: '/order/pay_notice/',
	// 订单记录确认订单
	addqrcloseorder: '/order/confirm_notice/',
	// 订单记录日志
	zycgOrderLog: '/yangcai/order/log/',
	zycgordercancel:"/order/cancel_notice/",//订单取消
	
	//批量发货
	logistics:"/order/express_list/",//批量发货获取物流
	consignmentlist:"/shipment/list/",//批量发货列表
	deliveryapi:"/shipment/import/",//批量发货发货接口
	deliveryapixg:"/shipment/update/",//批量发货修改发货接口
	batchdownload: "/shipment/download/",//批量发货下载文件

	interviewSystem:"/open/platform/admin_login/",	//访问其他系统
	interviewDetails:"/open/platform/",	//其他系统的详情
	interviewReset:"/open/platform/reset/",	//重置appkey

	// 供应商
	providerList:"/supplier/list",	//供应商列表
	delProvider:"/supplier",	//删除供应商、查看供应商详情、新增供应商、修改供应商
	allProvider:"/supplier/all",	//全部供应商
}


