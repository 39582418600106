<template>
	<div>
		<!-- 立即发货弹层 -->
		<el-dialog title="立即发货" class="deliverytc" :visible.sync="deliverytype">
			<div class="deliverybox">
				<div class="deliveryboxup">
					<el-table :data="deliveryboxcon.order_detail" style="width: 100%" ref="multipleTable"
						@selection-change="singletable">
						<el-table-column type="selection" width="40" :selectable="checkSelectable"></el-table-column>
						<el-table-column width="280" label="商品">
							<template slot-scope="scope">
								<div class="deliverytext">
									<p :title="scope.row.goods_name">{{ scope.row.goods_name }}</p>
									<div class="deliverybutlist clearfloat">
										<span v-for="(item, index) in scope.row.attr" :key="index">{{ item.specs_key
										}}：<strong>{{ item.specs_value }}</strong></span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="已发货数量/购买数量" align="center" width="160">
							<template slot-scope="scope">
								<div class="deliverynum">
									<template v-if="isZc">
										<p>{{ scope.row.num }}/{{ scope.row.num }}</p>
									</template>
									<template v-else>
										<p>{{ scope.row.send_num ? scope.row.send_num : 0 }}/{{ scope.row.num }}</p>
									</template>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="发货数量" align="center" width="160">
							<template slot-scope="scope">
								<div class="deliverylast">
									<template v-if="isZc">
										<el-input-number v-model="scope.row.num"
											@change="handleChange(scope.row.send_num ? scope.row.send_num : 0, scope.row.num, scope.row.delnumber)"
											:min="1" :max="10000000" :disabled="isZc">
											<!-- 政采情况下  禁止 -->
										</el-input-number>
									</template>
									<template v-else>
										<el-input-number v-model="scope.row.delnumber"
											@change="handleChange(scope.row.send_num ? scope.row.send_num : 0, scope.row.num, scope.row.delnumber)"
											:min="1" :max="10000000" :disabled="isZc">
											<!-- 政采情况下  禁止 -->
										</el-input-number>
									</template>
								</div>
							</template>
						</el-table-column>
						<template slot="empty">
							<div class="empty_data">
								<p>暂无数据</p>
							</div>
						</template>
					</el-table>
				</div>
				<div class="deliveryboxbut">
					<ul class="clearfloat">
						<li class="clearfloat">
							<span class="deliverytexted">收货地址：</span>
							<div class="deliveryright">
								<p>{{ deliveryboxcon.address }}</p>
							</div>
						</li>
						<li class="clearfloat">
							<span class="deliverytexted">配送方式：</span>
							<div class="deliveryright">
								<template>
									<el-radio v-model="radio" v-for="(item, index) in deliveryList" :label="item.value"
										:key="index">{{ item.label }}</el-radio>
								</template>
							</div>
						</li>
						<template v-if="radio == 1">
							<li class="clearfloat deliveryts">
								<span class="deliverytexted">物流公司：</span>
								<div class="deliveryright clearfloat">
									<template>
										<el-select v-model="demail" filterable placeholder="请选择">
											<el-option v-for="(item, index) in fastmail" :key="index" :label="item.value"
												:value="item.value">
											</el-option>
										</el-select>
									</template>
									<input type="text" v-model="express_no" placeholder="请输入物流单号" class="wlnumbers" />
								</div>
							</li>
						</template>
						<li class="clearfloat">
							<span class="deliverytexted">发货备注：</span>
							<div class="deliveryright">
								<textarea v-model="delremark" placeholder="买家将看到您写的发货备注"></textarea>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeattrubute">取 消</el-button>
				<el-button type="primary" @click="deliveryseal">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'orderdelivery',
	props: {
		"deliverybox": {
			type: String,
			default: ''
		},
		"isZc": {
			type: Boolean,
			default: false
		},
		"requestUrl": {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			deliverytype: false, //控制立即发货是否显示
			all_checked: true, //发货弹层全选状态
			radio: 1, //判断是否需要物流
			demail: "", //快递
			express_no: "",//物流单号
			delremark: "",//发货备注
			deliveryList: [{
				label: '需要物流',
				value: 1,
			},
			{
				label: '无需物流',
				value: 0,
			}
			],
			multipleSelection: [], //表格中选中的数据
			deliveryboxcon: {},
			fastmail: [],
			delist: []
		};
	},
	created() {
		// 创建时候，获取物流
		if (this.isZc) {
			this.$get(this.$apis.zycgOrderExpress).then(res => {
				// console.log(res, 'zycgOrderExpress');
				this.fastmail = res.data;
			})
		}
	},
	mounted() {
		
	},
	methods: {
		open(val, kd) {
			this.deliverytype = true;
			if (val) {
				if(val.order_detail.length<2){
					this.$nextTick(()=>{
						this.$refs.multipleTable.toggleAllSelection()
					})
					val.order_detail.forEach((item)=>{let send_num=Number(item.send_num)||0; item.delnumber=Number(item.num)-send_num})
				}else{
					for (let i = 0; i < val.order_detail.length; i++) {
						val.order_detail[i].delnumber = 1
					}
				}
				
				this.$set(this, 'deliveryboxcon', val);
				this.$nextTick(() => {
					val.order_detail.forEach(item => {
						if (this.isZc) {
							this.$refs.multipleTable.toggleRowSelection(item, true)
						}
					})
				})
			}
			// console.log(val,kd,this.isZc)
			if (kd) {
				this.$set(this, 'fastmail', kd);
			}
			if (this.isZc) {
				let data={
					"source":val.source
				}
				this.$get(this.$apis.logistics,data).then(res => {
				if (res.code == 200) {
						// console.log(res, 'zycgOrderExpress');
						this.fastmail = res.data;
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			}

		},
		// 单个表格被选中
		singletable(val) {
			this.multipleSelection = val;
			if (val.length == this.deliveryboxcon.order_detail.length) {
				this.all_checked = true;
			} else {
				this.all_checked = false;
			}
		},
		checkSelectable() {
			// 政采情况下，单选禁用
			if (this.isZc) {
				return false
			} else {
				return true
			}
		},
		deliveryseal() {
			this.delist = [];
			if (!this.isZc) {
				if (!this.multipleSelection.length) {
					this.$message({
						showClose: true,
						message: '请选择需要发货的商品',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				this.multipleSelection.map(item => {
					let newsobj = { "id": item.id, "send_num": item.delnumber }
					this.delist.push(newsobj);
				})
			} else {
				this.deliveryboxcon.order_detail.forEach(item => {
					let newsobj = { "id": item.id, "send_num": item.num }
					this.delist.push(newsobj);
				})
			}

			if (this.radio == 1) {
				// console.log(111)
				if (!this.demail) {
					this.$message({
						showClose: true,
						message: '请选择物流公司',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				if (!this.express_no) {
					this.$message({
						showClose: true,
						message: '请填写物流编号',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
			}

			if (this.radio == 0) {
				this.demail = '';
				this.express_no = '';
			}
			let data = {
				id: this.deliveryboxcon.id,
				remark: this.delremark,
				send_type: this.radio,
				express: this.demail,
				express_no: this.express_no,
				list: this.delist,
				source: this.deliveryboxcon.source
			};
			if (this.requestUrl) {
				this.$put(this.requestUrl + this.deliveryboxcon.id, data).then(res => {
					if (res.code == 200) {
						this.$emit("delsuccess")
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			} else {
				if (this.isZc) {
					this.$put(this.$apis.deliveryopenNew + this.deliveryboxcon.id, data).then(res => {
						if (res.code == 200) {
							this.$emit("delsuccess")
							this.closeattrubute()
						} else {
							this.common.message(this, res.message);
						}
					}).catch(err => {
						// console.log(err)
						this.common.message(this, err.message);
					});
				} else {
					data['source'] = this.deliveryboxcon.source <= 4 ? 4 : this.deliveryboxcon.source
					this.$put(this.$apis.deliveryopenNew + this.deliveryboxcon.id, data).then(res => {
						if (res.code == 200) {
							this.$emit("delsuccess")
							this.closeattrubute()
						} else {
							this.common.message(this, res.message);
						}
					}).catch(err => {
						// console.log(err)
						this.common.message(this, err.message);
					});
				}

			}

		},
		closeattrubute() {
			this.deliverytype = false;
			this.delremark = "";
			this.demail = "";
			this.express_no = "";
			this.delist = [];
		},
		handleChange(samill, big, delnumber) {
			let nums = Number(big - samill);
			if (delnumber > nums) {
				this.$message({
					showClose: true,
					message: '发货数量不可大于购买数量',
					type: 'warning',
					offset: 200,
					duration: 1000
				});
				return false;
			}
		},
	},
};
</script>

<style>
	/* 订单立即发货 */
	.deliverytc  .el-dialog {
	    width: 680px !important;
	    border-radius: 4px !important;
	}
	
	.deliverybox {
	    width: 100%;
	}
	
	.deliveryboxup {
	    width: 100%;
	    padding: 20px 14px;
	    box-sizing: border-box;
	    max-height: 290px;
	    overflow: auto;
	}
	
	.deliverytitle {
	    width: 100%;
	    height: 40px;
	    background: #f7f7f7;
	    border-bottom: 1px solid #f2f2f2;
	    box-sizing: border-box;
	}
	
	.deliverytitle p {
	    float: left;
	    font-size: 14px;
	    color: #333;
	    line-height: 39px;
	    font-weight: bold;
	    text-align: center;
	    padding: 0 10px;
	    box-sizing: border-box;
	}
	
	.deliverytitle p .el-checkbox {
	    color: #333;
	    font-weight: bold;
	}
	
	.deliverytitle p:nth-child(1) {
	    width: 50%;
	    text-align: left;
	}
	
	.deliverytitle p:nth-child(2) {
	    width: 25%;
	}
	
	.deliverytitle p:nth-child(3) {
	    width: 25%;
	}
	
	.deliveryboxcon {
	    width: 100%;
	}
	
	.deliverylist {
	    width: 100%;
	    border-bottom: 1px solid #f2f2f2;
	    box-sizing: border-box;
	}
	
	.delivery-item {
	    float: left;
	    padding: 14px 10px;
	    box-sizing: border-box;
	}
	
	.delivery-item:nth-child(1) {
	    width: 50%;
	}
	
	.delivery-item:nth-child(2) {
	    width: 25%;
	}
	
	.delivery-item:nth-child(3) {
	    width: 25%;
	}
	
	.dechecked {
	    float: left;
	}
	
	.deliverytext {
	    float: left;
	    width: calc(100% - 28px);
	}
	
	.deliverytext p {
	    font-size: 14px;
	    color: #333;
	    font-weight: 400;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    margin-bottom: 4px;
	}
	
	.deliverytext span {
	    display: block;
	    font-size: 12px;
	    color: #999;
	}
	
	.deliverytext span strong {
	    font-weight: 500;
	}
	
	.deliverynum {
	    width: 100%;
	    text-align: center;
	}
	
	.deliverynum p {
	    font-size: 14px;
	    color: #333;
	    font-weight: 400;
	}
	
	.deliverylast {
	    width: 100%;
	}
	
	.deliverylast .el-input-number {
	    width: 144px;
	}
	.deliverylast .el-input{width: 100%;}
	.deliverylast input {
	    width: 144px;
	    text-align: center;
	    font-size: 14px;
	    color: #333;
	    font-weight: 400;
	    outline: none;
	    border-radius: 4px;
	    border: 1px solid rgba(0, 0, 0, 0.1);
	    height: 38px;
	    display: block;
	    margin: 0 auto;
	    box-sizing: border-box;
	}
	
	.deliverylast .el-input-number__decrease:hover,
	.deliverylast .el-input-number__increase:hover {
	    color: var(--fontColor, #fb6638);
	}
	
	.deliverylast .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
	.deliverylast .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
	    border-color: var(--fontColor, #fb6638);
	}
	
	.deliveryboxbut {
	    width: 100%;
	    padding: 0 20px 10px 20px;
	    box-sizing: border-box;
	}
	
	.deliveryboxbut ul li {
	    padding: 0 10px;
	    box-sizing: border-box;
	    width: 100%;
	    line-height: 32px;
	    margin-bottom: 10px;
	}
	
	.deliveryboxbut ul li:last-child {
	    margin-bottom: 0;
	}
	
	.deliveryboxbut ul li.deliveryts {
	    line-height: 38px;
	}
	
	.deliveryboxbut ul li .deliverytexted {
	    float: left;
	    font-size: 14px;
	    color: #666;
	    font-weight: 500;
	    width: 70px;
	    margin-right: 20px;
	}
	
	.deliveryright {
	    float: left;
	    width: calc(100% - 90px);
	}
	
	.deliveryright p {
	    font-size: 14px;
	    color: #666;
	    font-weight: 500;
	}
	
	.deliveryright .el-radio {
	    font-size: 14px;
	    color: #666;
	    margin-right: 20px;
	}
	
	.deliveryright .el-radio__inner {
	    width: 18px;
	    height: 18px;
	    border-color: #dddee1;
	    margin-top: -1px;
	    margin-right: 0px;
	}
	
	.deliveryright .el-radio__label {
	    padding-left: 8px;
	}
	
	.deliveryright .el-select {
	    float: left;
	    width: 148px;
	    height: 38px;
	    margin-right: 10px;
	}
	.deliveryright .el-select .el-input{width:100%;}
	
	.deliveryright .wlnumbers {
	    float: left;
	    width: 265px;
	    height: 40px;
	    padding: 0 12px;
	    border-radius: 4px;
	    border: 1px solid rgba(0, 0, 0, 0.1);
	    font-size: 14px;
	    color: #333;
	    box-sizing: border-box;
		outline: none;
		transition: all 0.3s;
	}
	.deliveryright input:hover{border-color: var(--fontColor,#fb6638);}
	.deliveryright textarea {
	    width: 100%;
	    border-radius: 4px;
	    border: 1px solid rgba(0, 0, 0, 0.1);
	    font-size: 14px;
	    color: #333;
	    resize: none;
	    padding: 4px 12px;
	    box-sizing: border-box;
	    height: 72px;
		outline: none;
		transition: all 0.3s;
	}
	.deliveryright textarea:hover{border-color: var(--fontColor,#fb6638);}
	
	
.deliverybutlist {
	display: block;
}
.deliverylast .el-input-number{line-height: 36px;}
.deliverylast .el-input-number__decrease, .el-input-number__increase{height: 36px;}
.deliverybutlist span {
	float: left;
	margin-right: 6px;
}

.deliverybutlist span:last-child {
	margin-right: 0;
}

.deliveryboxup .el-table .cell {
	line-height: 1.5;
	overflow: visible;
}

.deliveryboxup .el-table th.el-table__cell {
	background: #f7f7f7;
	color: #333;
}

.deliveryboxup .el-table-column--selection .cell {
	padding: 0 10px;
}

.deliveryboxup .el-checkbox__inner {
	width: 18px;
	height: 18px;
}

.deliveryboxup .el-table .el-table__cell {
	padding: 8px 0;
}

.deliveryboxup .el-checkbox__inner::after {
	top: 3px;
	left: 6px;
}

.deliveryboxup .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
	top: 7px;
}
</style>